import { Injectable } from '@angular/core';
import { Cookie, CookieCategories } from '../legal/cookie';
import { CookieConsentService } from '../legal/cookie-consent.service';
import { CookieService } from '../legal/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public static readonly cookiesConsentedKey = "cookies_consented";

  private cache = new Map();

  //Prefix for cookies that can be moved between local storage and session storage
  private storagePrefix = "moveable-cookie_"

  constructor(private cookieConsentService: CookieConsentService, public cookieService: CookieService) {
    cookieConsentService.onConsentChange.subscribe(consentUpdate => {
      consentUpdate.cookies.forEach(cookieUpdate => {
        if (cookieUpdate.consented)
          this.moveCookieToLocalStorage(cookieUpdate.cookie);
        else
          this.moveCookieToSessionStorage(cookieUpdate.cookie);
      });
    })
  }

  public setItem(cookie: Cookie, value: string) {
    //To distinguish stuff directly written to sessionStorage from stuff written to it because lacking coockie consent

    var key = this.storagePrefix + cookie.key;
    if (this.cookieConsentService.getCookieConsent(cookie))
      localStorage.setItem(key, value);
    else
      sessionStorage.setItem(key, value);

  }

  public getItem(cookie: Cookie): string {
    var key = this.storagePrefix + cookie.key;

    let val;
    if (this.cookieConsentService.getCookieConsent(cookie))
      val = localStorage.getItem(key);
    else
      val = sessionStorage.getItem(key);
    return val;
  }

  public deleteItem(cookie: Cookie) {
    var key = this.storagePrefix + cookie.key;

    if (this.cookieConsentService.getCookieConsent(cookie))
      localStorage.removeItem(key);
    else
      sessionStorage.removeItem(key);

  }

  public moveCookieToLocalStorage(cookie: Cookie) {
    const prefixedKey = this.storagePrefix + cookie.key;
    const val = sessionStorage.getItem(prefixedKey);
    localStorage.setItem(prefixedKey, val);
    sessionStorage.removeItem(prefixedKey);
  }


  public moveAllCookiesToLocalStorage() {
    //Copy stuff from sessionstorage to localstorage
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      const val = sessionStorage.getItem(key);
      if (key.startsWith(this.storagePrefix)) {
        localStorage.setItem(key, val);
        sessionStorage.removeItem(key);
      }
    }
  }

  public moveCookieToSessionStorage(cookie: Cookie) {
    if (cookie.category != CookieCategories.NECESSARY) {
      const prefixedKey = this.storagePrefix + cookie.key;
      const val = localStorage.getItem(prefixedKey);
      sessionStorage.setItem(prefixedKey, val);
      localStorage.removeItem(prefixedKey);
    }
  }


  public moveOptionalCookiesToSessionStorage() {
    //Copy stuff from localstorage to sessionstorage
    for (let i = 0; i < localStorage.length; i++) {
      const prefixedKey = localStorage.key(i);
      const key = prefixedKey.substr(this.storagePrefix.length - 1, prefixedKey.length - this.storagePrefix.length - 1)
      const val = localStorage.getItem(prefixedKey);
      if (prefixedKey.startsWith(this.storagePrefix) && this.cookieService.getByKey(key).category != CookieCategories.NECESSARY)
        sessionStorage.setItem(prefixedKey, val);
      localStorage.removeItem(prefixedKey);
    }
  }

}
