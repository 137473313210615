import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentBarComponent } from './consent-bar/consent-bar.component';
import { RouterModule } from '@angular/router';
import { CookieSelectComponent } from './cookie-select/cookie-select.component';
import { CookieEditorComponent } from './cookie-editor/cookie-editor.component';
import { CookieEditorPopupComponent } from './cookie-editor-popup/cookie-editor-popup.component';
import { ModalBaseComponent } from '../popup-base/modal-base/modal-base.component';
import { PopupBaseModule } from '../popup-base/popup-base.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookiesTableComponent } from './cookies-table/cookies-table.component';
import { AgbComponent } from './pages/agb/agb.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { WiederrufComponent } from './pages/wiederruf/wiederruf.component';
import { AppRoutes } from '../Routes';

@NgModule({
  declarations: [ConsentBarComponent,
    CookieSelectComponent,
    CookieEditorComponent,
    CookieEditorPopupComponent,
    CookiesTableComponent,
    AgbComponent,
    ImpressumComponent,
    PrivacyPolicyComponent,
    WiederrufComponent],
  imports: [
    RouterModule.forChild([

      { path: AppRoutes.agb, component: AgbComponent },
      { path: AppRoutes.impressum, component: ImpressumComponent },
      { path: AppRoutes.privacyPolicy, component: PrivacyPolicyComponent },
      { path: AppRoutes.wiederruf, component: WiederrufComponent },
    ]),
    CommonModule,
    NgbModule,
    RouterModule,
    PopupBaseModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ConsentBarComponent,
    CookieEditorComponent
  ]
})
export class LegalModule { }
