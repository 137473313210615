import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import noUiSlider from "nouislider";

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit, AfterViewInit {

  @Input()
  src1;

  @Input()
  src2;

  @Input()
  revealProgress = 50;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    var slider = document.getElementById("slider");

  }

}
