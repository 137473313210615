import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Component({
  selector: 'app-verify-password',
  templateUrl: './verify-password.component.html',
  styleUrls: ['./verify-password.component.scss']
})
export class VerifyPasswordComponent implements OnInit {

  //True if correct, false if not
  @Output()
  onPasswordVerified = new EventEmitter<boolean>();
  password;
  wrongPassword;

  constructor(private auth: AuthenticationService) { }

  ngOnInit() {
  }

  submit() {
    this.auth.verifyPassword(this.password).subscribe(val => {
      this.onPasswordVerified.emit(true);
      this.wrongPassword = false;
    },
      err => {
        this.onPasswordVerified.emit(false);
        this.wrongPassword = true;
      })
  }

}
