import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';

@Component({
  selector: 'app-clipper',
  templateUrl: './clipper.component.html',
  styleUrls: ['./clipper.component.scss']
})
export class ClipperComponent implements OnInit, OnChanges {

  /**
   * Clipping from 0-100
   * 0: no clipping
   */
  @Input()
  progress: number;

  @Input()
  mode: "slide" | "blur" = "slide"

  @ViewChild("clip")
  clip: ElementRef;

  class = "clip0";

  constructor() { }
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.progress) {
      if (this.mode != "blur")
        this.class = "clip" + Math.round(this.progress);


    }
  }


  ngOnInit(): void {
  }

}
