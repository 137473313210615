import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingHeroSectionComponent } from './landing-hero-section/landing-hero-section.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PageComponentsModule } from '../page-components/page-components.module';
import { NouisliderModule } from 'ng2-nouislider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandingHeroSectionStaticComponent } from './landing-hero-section/landing-hero-section-static/landing-hero-section-static.component';
import { LandingUspsSectionComponent } from './landing-usps-section/landing-usps-section.component';
import { LandingHowItWorksSectionComponent } from './landing-how-it-works-section/landing-how-it-works-section.component';
import { LandingExamplesSectionComponent } from './landing-examples-section/landing-examples-section.component';
import { FormComponentsModule } from '../form-components/form-components.module';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { LandingRequestFormSectionComponent } from './landing-request-form-section/landing-request-form-section.component';
import { RouterModule } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha';




@NgModule({
  declarations: [
    LandingHeroSectionComponent,
    LandingPageComponent,
    LandingHeroSectionStaticComponent,
    LandingUspsSectionComponent,
    LandingHowItWorksSectionComponent,
    LandingExamplesSectionComponent,
    LandingRequestFormSectionComponent,
  ],
  imports: [
    FormComponentsModule,
    CommonModule,
    RecaptchaModule,
    ReactiveFormsModule,
    FormElementsModule,
    PageComponentsModule,
    RouterModule,
    NouisliderModule,
    FormsModule,
  ]
})
export class LandingModule { }
