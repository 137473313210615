import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/Routes';
import { UserService } from 'src/app/auth/user.service';

@Component({
  selector: 'app-info-next-step-email-sent',
  templateUrl: './info-next-step-email-sent.component.html',
  styleUrls: ['./info-next-step-email-sent.component.scss']
})
export class InfoNextStepEmailSentComponent implements OnInit {

  constructor(private router: Router, private userService: UserService,
  ) {
  }

  ngOnInit() {
    if (this.userService.currentUser.isEmailVerified) {
      this.router.navigateByUrl(AppRoutes.emailVerified);
    }


  }
  goToHome() {
    this.router.navigateByUrl(AppRoutes.home);
  }
}
