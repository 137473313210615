import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EmailVerifyErrorComponent } from './email/email-verify-error/email-verify-error.component';
import { InfoNextStepEmailSentComponent } from './email/info-next-step-email-sent/info-next-step-email-sent.component';
import { ResendEmailButtonComponent } from './email/resend-email-button/resend-email-button.component';
import { VerifiedComponent } from './email/verified/verified.component';
import { AuthPagesModule } from '../auth-pages/auth-pages.module';
import { AppRoutes } from '../Routes';
import { RequestThanksComponent } from './request-thanks/request-thanks.component';
import { ProduktauswahlComponent } from './produktauswahl/produktauswahl.component';
import { PageComponentsModule } from '../page-components/page-components.module';
import { FormComponentsModule } from '../form-components/form-components.module';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { NewsletterUnsubscribedComponent } from './newsletter-unsubscribed/newsletter-unsubscribed.component';

@NgModule({
  declarations: [EmailVerifyErrorComponent,
    InfoNextStepEmailSentComponent,
    ResendEmailButtonComponent,
    VerifiedComponent,
    RequestThanksComponent,
    ProduktauswahlComponent,
    NewsletterUnsubscribedComponent],
  imports: [
    CommonModule,
    AuthPagesModule,
    RouterModule.forChild([

      {
        path: AppRoutes.unverifiedEmailError, component: EmailVerifyErrorComponent,
      },
      {
        path: AppRoutes.customizer2, component: ProduktauswahlComponent
      },

      { path: AppRoutes.emailVerified, component: VerifiedComponent, data: { transparentNavbar: true } },
    ]),
    PageComponentsModule,
    FormComponentsModule,
    FormElementsModule,
  ],
  exports: [
    ProduktauswahlComponent,
  ]
})
export class PagesModule { }
