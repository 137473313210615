import { LoginToken } from './login-token';

export interface UserInfo {
    email: string;
    id: string;
    role: string;
    isEmailVerified: boolean;
    isTestUser: boolean;
    loginToken: LoginToken;
    isLoggedIn: boolean;
}


export function GetLoggedOutUserInfo(): UserInfo {
    let userInfo: UserInfo = {
        isLoggedIn: false
    } as UserInfo;
    return userInfo;
}