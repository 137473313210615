import { Injectable } from '@angular/core';
import { FinishedWorkloadDto } from '../api-interface/clients';
import { StorageService } from '../services/storage.service';
import { Cookies } from '../legal/cookie.service';

@Injectable({
  providedIn: 'root'
})


export class CustomizedWorkload {
  orderId: string;
  workload: FinishedWorkloadDto;
  opacity: number = 100;

}

