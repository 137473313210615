import { Component, OnInit, OnChanges, forwardRef } from '@angular/core';
import { BaseFormGroupControlValueAccessor } from 'src/app/form-components/base-form-group-control-value-accessor';
import { FormBuilder, NG_VALUE_ACCESSOR, FormControl, FormArray } from '@angular/forms';
import { StylesAdminClient, Style } from 'src/app/api-interface/clients';
import { BaseControlValueAccessor } from 'src/app/form-components/base-control-value-accessor';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from 'src/app/api-interface/api-endpoints';

@Component({
  selector: 'app-style-editor-item',
  templateUrl: './style-editor-item.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => StyleEditorItemComponent),
    }
  ],
  styleUrls: ['./style-editor-item.component.css']
})
export class StyleEditorItemComponent extends BaseControlValueAccessor implements OnInit {

  preview;
  deleted = false;

  constructor(private fb: FormBuilder, private stylesClient: StylesAdminClient, private http: HttpClient, private ep: ApiEndpoints) {
    super();

  }

  delete() {
    if (confirm("Really delete?")) {
      this.http.delete(this.ep.getDeleteStyleUrl(this.value.styleId)).subscribe(val => {
        this.deleted = true;
      },
        err => {
          alert("Couldnt delete");
          console.log(err);

        })
    }
  }

  public get value(): Style {
    return this.val;
  }
  public set value(val: Style) {
    this.val = val;
    this.onChange(val);
    this.onTouched();

    this.setPreviewUrl();

    this.onValueUpdated(val);
  }

  private setPreviewUrl() {
    if (this.value && this.value.styleId) {
      this.preview = "https://artai-styles.s3.eu-central-1.amazonaws.com/" + this.value.styleId + "-md.jpg";
      console.log(this.preview);

    }
  }

  styleBucket = "artai-styles";
  ngOnInit(): void {



  }


  update() {
    this.notifyChangedByUser();
  }


  save() {

    this.value.createdAt = new Date();
    this.http.post(environment.apiUrl + "/v1/styles", this.value).subscribe(val => {
      alert("success");
      this.deleted = false;
    },
      err => alert("error"));
  }

}
