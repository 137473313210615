import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/auth/user.service';
import { AppRoutes } from 'src/app/Routes';
import { UserRole } from 'src/app/auth/data/user-role.enum';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Component({
  selector: 'app-verified',
  templateUrl: './verified.component.html',
  styleUrls: ['./verified.component.scss']
})
//Redirects the user to the right page, depending on his role, after his email was verified
export class VerifiedComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, public userService: UserService, private router: Router, public auth: AuthenticationService) {

  }

  verifyEmail() {
    let user = this.userService.currentUser;
    user.isEmailVerified = true;
    this.userService.currentUser = user;
    this.router.navigateByUrl(AppRoutes.home);
  }

  ngOnInit() {
    if (this.userService.currentUser.isLoggedIn) {
      this.verifyEmail();
    }
    else {
      this.auth.onLoggedIn.subscribe(val => {
        this.verifyEmail();
      });
    }
  }

}
