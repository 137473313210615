import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfoComponent } from './info/info.component';
import { TruncatedTextComponent } from './truncated-text/truncated-text.component';
import { TruncateLinesPipe } from './truncate-lines.pipe';
import { RouterModule } from '@angular/router';
import { TruncateCharsPipe } from './truncate-chars.pipe';
import { FloatingActionButtonComponent } from './floating-action-button/floating-action-button.component';

@NgModule({
  declarations: [
    InfoComponent,
    TruncatedTextComponent,
    TruncateLinesPipe,
    TruncateCharsPipe,
    FloatingActionButtonComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
  ],
  exports: [
    InfoComponent,
    TruncatedTextComponent,
    TruncateLinesPipe,
    FloatingActionButtonComponent,

  ]
})
export class SimpleComponentsModule { }
