import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { UserService } from 'src/app/auth/user.service';
import { ApiEndpoints } from 'src/app/api-interface/api-endpoints';
import { Guid } from 'src/app/util/guid';
import { ImageUpload } from './image-upload';
@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
  public static readonly MAX_SIZE_MB = 2;
  public static readonly MAX_SIZE = ImageUploaderComponent.MAX_SIZE_MB * 1000 * 1000;
  public MAX_SIZE_IMG = ImageUploaderComponent.MAX_SIZE_MB;




  @Input()
  canChangeImage = true;

  @Input()
  multiple = false;

  @Input()
  userId: string;

  @Output()
  public onUploaded: EventEmitter<any>;

  //Emits null if the selected image was cleared
  @Output()
  public onFilesSelected: EventEmitter<SelectedFile[]> = new EventEmitter<SelectedFile[]>();

  public uploader: FileUploader;
  public isHovering: boolean = false;
  public isFileTooBig = false;

  public fileOverBase(e: any): void {
    this.isHovering = e;
  }

  constructor(private endpoints: ApiEndpoints, userService: UserService) {
    if (this.userId == null)
      this.userId = userService.currentUser.id;

    this.uploader = new FileUploader({ url: endpoints.getUploadImageUrl(this.userId) });
    if (this.userId) {
      this.uploader.authToken = "Bearer " + userService.currentUser.loginToken.jwtToken;
      this.uploader.authTokenHeader = "Authorization";
    }

    this.uploader.autoUpload = false;
    this.uploader.options.queueLimit = this.multiple ? 15 : 1;
    this.uploader.options.maxFileSize = ImageUploaderComponent.MAX_SIZE;

    this.onUploaded = this.uploader.response;
  }

  filesSelected(files) {
    let handle = Guid.newGuid();
    this.uploader.options.additionalParameter = { "handle": handle };

    let selectedFiles: SelectedFile[] = [];
    //Only start uploading valid files
    let keys = Object.keys(files);
    keys.forEach(key => {
      let x = files[key];

      if (x.size >= ImageUploaderComponent.MAX_SIZE) {
        //Mark invalid files as such
        this.uploader.queue[keys.indexOf(key)].isError = true;
        this.isFileTooBig = true;
      }
      else {
        //Item is valid, upload it
        let fileItem = this.uploader.queue.find(fileItem => fileItem.file.name == x.name && fileItem.file.size == x.size);
        fileItem.upload();
        selectedFiles.push(new SelectedFile(fileItem, handle));
      }
    });

    this.onFilesSelected.emit(selectedFiles);
  }

  clearQueue() {
    this.onFilesSelected.emit([]);
    this.uploader.queue.splice(0, this.uploader.queue.length);
    this.isFileTooBig = false;
  }

  ngOnInit() {
  }

}

export class SelectedFile {
  constructor(file: FileItem, handle: string) {
    this.file = file;
    this.handle = handle;
  }
  file: FileItem;
  handle: string;
}

export interface UploadedImage {
  upload: ImageUpload,
  handle: string;
}