import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from '../base-control-value-accessor';
import { catchError, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { isNullOrWhitespace } from 'src/app/util/helpers';
import { ZipCode } from 'src/app/zip-code/zip-code';
import { ZipCodeService } from 'src/app/zip-code/zip-code.service';


@Component({
  selector: 'app-zip-code-form-field',
  templateUrl: './zip-code-form-field.component.html',
  styleUrls: ['./zip-code-form-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ZipCodeFormFieldComponent),
    }
  ],
})
export class ZipCodeFormFieldComponent extends BaseControlValueAccessor implements OnInit {

  @Input() submitted: boolean;
  @Input() transparent: false;
  @Input() label: String;
  @Input() alternativeStyle = false;

  @Input() labelPosition: 'left' | 'top' | 'none' = "top";
  @Input() isOrange: boolean;

  @Input() showErrorMsg = true;
  //True shows a second (disabled) text field that automatically loads the cityname of the zipcode
  @Input() showCityName: boolean = true;
  //Form control of this for setting errors - optional
  @Input() control: AbstractControl;
  //OnBlur of the zipcode input
  @Output() blur = new EventEmitter<ZipCode>();
  //Wether to return the ZipCode object (with cityname) or just the zipcode string
  @Input() zipCodeOnly = false;
  public zipCode$: Observable<ZipCode>;


  public zipCode: ZipCode;
  private cityName;

  public get value() {
    return this.val;
  }

  public set value(val: string) {

    if (val != null)
      this.val = val;
    if (!isNullOrWhitespace(val))
      setTimeout(() => {
        this.loadZipCode(false);
        this.changeTouched();

      }, 5);
  }

  changeTouched() {
    this.onChange(this.value);
    this.onTouched();
  }

  constructor(private zipServ: ZipCodeService) {
    super();
  }

  ngOnInit() {
  }

  onBlur() {
    this.loadZipCode(true);
  }

  loadZipCode(blur: boolean) {

    this.zipCode$ = this.zipServ.getZipCode(this.value);
    this.zipCode$.subscribe(
      val => {

        this.cityName = val.cityName;
        this.zipCode = val;
        this.control.setErrors(null);
        if (blur)
          this.blur.emit(this.zipCode);
      },
      err => {
        this.zipCode = new ZipCode();
        this.zipCode.cityName = null;
        this.zipCode.plz = this.value;
        this.control.setErrors({ 'invalidZip': true });
        if (blur)
          this.blur.emit(this.zipCode);
      }
    )
  }



}