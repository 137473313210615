import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized, Data } from '@angular/router';
import { Subscription, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteDataService implements OnDestroy {

  //Object of all route data collapsed
  //If two of the same keys are defined, the one further towards the end of the url is used
  /*
  Example: s-expample.com/a/b/c
    Route Data
    /a    {a:1}
    /b    {b:2}
    /c    {a:"c"}
    Result: {a:"c", b:2}
  */
  public routeData: any = {};

  //Emits the new route data object on change
  public routesUpdated = new Subject<any>();


  private routerSub: Subscription;


  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  init() {
    this.updateRoutes(this.activatedRoute.snapshot)
    this.routerSub = this.router.events.subscribe((data) => {
      this.routeData = {}
      if (data instanceof RoutesRecognized) {
        this.updateRoutes(data.state.root);
      }
    });
  }

  updateRoutes(firstChild) {
    let tries = 0;
    while (firstChild && tries < 15) {
      Object.keys(firstChild.data).forEach(key => this.routeData[key] = firstChild.data[key]);
      firstChild = firstChild.firstChild;
      tries++;
    }
    this.routesUpdated.next(this.routeData);
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }
}

export interface RoutesData extends Data {

}