import { Component, OnInit, ViewChildren, QueryList, ElementRef, Renderer2, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomizerControlsPageComponent } from '../customizer-controls-page/customizer-controls-page.component';
import { CustomizerControlsService } from '../customizer-controls.service';
import { PosterService, posterVariations, hdDownload, getPosterMinPrice, sdDownloadResolution, ProductVariation } from '../poster.service';
import { FinishedRequestDto } from 'src/app/api-interface/clients';
import { VirtualTimeScheduler } from 'rxjs';

@Component({
  selector: 'app-customizer-controls',
  templateUrl: './customizer-controls.component.html',
  styleUrls: ['./customizer-controls.component.scss']
})
export class CustomizerControlsComponent implements OnInit, AfterViewInit {


  @Input()
  request: FinishedRequestDto;
  @Input()
  selectedWorkload;
  @Output()
  selectedWorkloadChange = new EventEmitter();

  @ViewChildren(CustomizerControlsPageComponent)
  pages: QueryList<CustomizerControlsPageComponent>;

  @ViewChildren(CustomizerControlsPageComponent, { read: ElementRef })
  pageElements: QueryList<ElementRef>;

  shownPageId: string;

  selectedProduct: ProductVariation = posterVariations[0];
  productVariations = posterVariations;
  minPosterPrice = getPosterMinPrice();
  hdDownload = hdDownload;
  sdDownloadResolution = sdDownloadResolution;

  constructor(private renderer: Renderer2,
    public posterServ: PosterService,
    private customizerControlsService: CustomizerControlsService) { }
  ngAfterViewInit(): void {
    this.hideAllPages();
    this.customizerControlsService.nextPage();
    console.log(this.request);

  }

  updateWorkload() {
    this.selectedWorkloadChange.emit(this.selectedWorkload);
  }

  show(pageId, reverse = false) {



    if (pageId == this.shownPageId || !pageId)
      return;




    let pagesArr = this.pages.toArray();
    let nextIndex = pagesArr.findIndex(x => x.pageId == pageId);
    let next = pagesArr[nextIndex];
    let nextElement = this.pageElements.toArray()[nextIndex];

    let curIndex = pagesArr.findIndex(x => x.pageId == this.shownPageId);
    let cur = pagesArr[curIndex];
    let curElement = this.pageElements.toArray()[curIndex];



    if (!next)
      return;

    if (!this.shownPageId) {
      //Show without animation
      this.renderer.setStyle(nextElement.nativeElement, "transform", "none")
      this.renderer.removeClass(nextElement.nativeElement, "d-none");

      this.shownPageId = pageId;

      return;
    }


    this.shownPageId = pageId;


    let animDuration = 500;
    var nextTranslate = reverse ? "translate(-100vw,0)" : "translate(100vw,0)";

    this.renderer.setStyle(nextElement.nativeElement, "transform", nextTranslate)
    this.renderer.removeClass(nextElement.nativeElement, "d-none");
    this.renderer.setStyle(nextElement.nativeElement, "position", "absolute")
    this.renderer.setStyle(nextElement.nativeElement, "top", "0")

    var curTranslate = reverse ? "translate(100vw,0)" : "translate(-100vw,0)";

    this.renderer.setStyle(curElement.nativeElement, "transform", curTranslate)
    this.renderer.setStyle(nextElement.nativeElement, "z-index", "10")

    setTimeout(() => {
      this.renderer.setStyle(nextElement.nativeElement, "transform", "none")

      setTimeout(() => {
        this.hide(curElement, reverse);
        this.renderer.setStyle(nextElement.nativeElement, "position", "static")

      }, animDuration);

    }, 50);




  }

  hide(element: ElementRef, isReverse) {
    this.renderer.setStyle(element.nativeElement, "position", "static")


    var translate = isReverse ? "translate(100vw,0)" : "translate(-100vw,0)";

    this.renderer.setStyle(element.nativeElement, "transform", translate)
    this.renderer.addClass(element.nativeElement, "d-none");
  }

  ngOnInit() {
    this.customizerControlsService.onPageChanged.subscribe(newPage => this.show(newPage.id, newPage.isBack));
  }

  hideAllPages() {
    console.log(this.pageElements);

    this.pageElements.toArray().forEach(elem => {
      this.hide(elem, false);
    })
  }


  selectWorkload(wl) {
    this.selectedWorkload = wl;
    this.selectedWorkloadChange.emit(wl);
  }

  usePoster() {
    this.customizerControlsService.insertPages(this.customizerControlsService.posterPages);
    this.customizerControlsService.nextPage();
  }

  useDownload() {
    //TODO:
  }

  useHDDownload() {
    this.selectedProduct = hdDownload;
    this.customizerControlsService.insertPages(["review"]);
    this.customizerControlsService.nextPage();
  }


  useFormat(formatSize) {
    this.customizerControlsService.nextPage();
  }
}
