import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { BaseControlValueAccessor } from 'src/app/form-components/base-control-value-accessor';
import { NG_VALUE_ACCESSOR, FormBuilder } from '@angular/forms';
import { BaseFormGroupControlValueAccessor } from 'src/app/form-components/base-form-group-control-value-accessor';
import { AuthorizationService } from 'src/app/auth/authorization.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { UserService } from 'src/app/auth/user.service';
import { AppRoutes } from 'src/app/Routes';

@Component({
  selector: 'app-login-register-options',
  templateUrl: './login-options.component.html',

  styleUrls: ['./login-options.component.scss']
})
export class LoginOptionsComponent implements OnInit {

  @Input()
  altRoute: string;

  @Input()
  altText: string;
  requestPwChangeUrl = AppRoutes.user.requestPasswordChange;

  constructor(public userService: UserService) {
  }

  ngOnInit() {

  }

}
