import { Component, OnInit } from '@angular/core';
import { ApiEndpoints } from 'src/app/api-interface/api-endpoints';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/auth/user.service';
import { sendRequest } from 'selenium-webdriver/http';

@Component({
  selector: 'app-resend-email-button',
  templateUrl: './resend-email-button.component.html',
  styleUrls: ['./resend-email-button.component.scss']
})
export class ResendEmailButtonComponent implements OnInit {

  state: "new" | "loading" | "done" = "new"

  constructor(private endpoints: ApiEndpoints, private http: HttpClient, private userService: UserService) { }

  click() {
    if (this.state == "new") {
      this.sendRequest();
      this.state = "loading";
      setTimeout(() => {
        this.state = "done";
      }, 1000);
    }
  }

  sendRequest() {
    this.http.post(this.endpoints.getResendActivationEmailUrl(), {}).subscribe();
  }

  ngOnInit() {
  }

}
