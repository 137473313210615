import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { BaseControlValueAccessor } from '../base-control-value-accessor';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ],
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends BaseControlValueAccessor implements OnInit {

  @Input()
  smallText: boolean = false;

  @Input()
  captchaStyle = false;

  @Input()
  control: FormControl;

  @Input()
  submitted: boolean;

  getRandomId() {
    return Math.floor((Math.random() * 100000) + 1);

  }

  id;
  constructor() {
    super();
    this.id = this.getRandomId();
  }

  ngOnInit() {
    console.log(this.control);

  }

}
