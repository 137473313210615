import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './theme-pages/home/home.component';
import { ProfileComponent } from './theme-pages/profile/profile.component';
import { SignupComponent } from './theme-pages/signup/signup.component';
import { LandingComponent } from './theme-pages/landing/landing.component';
import { LoginComponent } from './theme-pages/login/login.component';
import { LandingPageComponent } from './landing/landing-page/landing-page.component';
import { AppRoutes } from './Routes';
import { RequestThanksComponent } from './pages/request-thanks/request-thanks.component';
import { AgbComponent } from './legal/pages/agb/agb.component';
import { PrivacyPolicyComponent } from './legal/pages/privacy-policy/privacy-policy.component';
import { ImpressumComponent } from './legal/pages/impressum/impressum.component';
import { ErrorLandingComponent } from './pages/error-landing-page/error-landing.component';
import { NewsletterUnsubscribedComponent } from './pages/newsletter-unsubscribed/newsletter-unsubscribed.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'user-profile', component: ProfileComponent },
  { path: 'register', component: SignupComponent },
  { path: 'login', component: LoginComponent },
  { path: '', component: LandingPageComponent, data: { hideNavbar: true } },
  { path: 'agb', component: AgbComponent },
  { path: AppRoutes.errorLanding, component: ErrorLandingComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'impressum', component: ImpressumComponent },
  {
    path: AppRoutes.requestThankYou, component: RequestThanksComponent,
  },
  {
    path: AppRoutes.newsletterUnsubscribed, component: NewsletterUnsubscribedComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
