
export function getFileExtension(file: File) {
    return file.name.split(".").pop();
}

export function getContentType(extension: string) {
    if (extension.startsWith("."))
        extension = extension.substring(1);
    extension = extension.toLowerCase();
    // MIME types array
    let mimeTypes = {

        "jpe": "image/jpeg",
        "jpeg": "image/jpeg",
        "jpg": "image/jpeg",
        "png": "image/png",
        "tif": "image/tiff",
        "tiff": "image/tiff",


    };


    return mimeTypes[extension]; // return the array value
}