import { Injectable, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../auth/authentication.service';
import { UserService } from '../auth/user.service';
import { Router } from '@angular/router';
import { RegistrationValidator } from '../auth/registration-validator';
import { UserRole } from '../auth/data/user-role.enum';


export class RegistrationController implements OnInit {


  public form: FormGroup;
  public validated = false;
  public submitted = false;
  public hasRegistrationError = false;
  public errorCode: number;
  public tryAgainInSeconds: number;

  get successfullySubmitted() { return this.validated && this.submitted; }

  constructor(private formBuilder: FormBuilder, private authService: AuthenticationService, public userService: UserService, private router: Router) {
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      password_repeat: ["", Validators.required]
    }, {
      //Checks if the passwords match
      validator: RegistrationValidator.validate.bind(this)
    });
  }

  onSubmit() {
    this.submitted = true;
    this.hasRegistrationError = false;

    if (this.form.invalid) {
      this.hasRegistrationError = true;
      return;
    }
    this.validated = true;

    let role = UserRole.Customer;
    //Send the request to the server
    this.authService.register(this.form.controls.email.value, this.form.controls.password.value, role)
      .subscribe(
        val => {
          this.hasRegistrationError = false;
          this.errorCode = undefined;
        }, error => {
          this.hasRegistrationError = true;
          this.errorCode = error.status;
          this.tryAgainInSeconds = error.headers.get('retry-after');
        })
  }


  ngOnInit() {
    if (this.userService.currentUser.isLoggedIn) {
      this.router.navigateByUrl("");
    }
  }


}
