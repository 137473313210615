import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminClient, WorkloadDto, FinishedWorkloadDto } from 'src/app/api-interface/clients';

@Component({
  selector: 'app-admin-result-preview',
  templateUrl: './admin-result-preview.component.html',
  styleUrls: ['./admin-result-preview.component.css']
})
export class AdminResultPreviewComponent implements OnInit {

  workloads: FinishedWorkloadDto[] = [];
  progress = 50;
  constructor(private adminClient: AdminClient) { }

  ngOnInit(): void {
    this.adminClient.getFinishedWorkloads(10, 0).subscribe(val => {
      this.workloads = val;
      this.autoRefresh();
    })
  }

  autoRefresh() {

  }

  refresh() {
    this.adminClient.getFinishedWorkloads(this.workloads.length, 0).subscribe(val => {
      if (val.length > this.workloads.length)
        this.workloads = val;


    })
  }

  loadMore() {
    this.adminClient.getFinishedWorkloads(10, this.workloads.length).subscribe(val => {
      this.workloads = this.workloads.concat(val);
    })
  }

}
