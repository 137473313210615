import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestClient, RequestDto } from 'src/app/api-interface/clients';
import { Guid } from 'src/app/util/guid';
import { fromEventPattern } from 'rxjs';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/Routes';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/auth/user.service';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-landing-request-form-section',
  templateUrl: './landing-request-form-section.component.html',
  styleUrls: ['./landing-request-form-section.component.scss']
})
export class LandingRequestFormSectionComponent implements OnInit {
  requestId;
  constructor(public userService: UserService,
    private fb: FormBuilder,
    private requests: RequestClient, private router: Router,
    private http: HttpClient,
    private requestServ: RequestService) {

    this.form = this.fb.group({
      requestId: [],
      email: [null, [Validators.required, Validators.email]],
      styleIds: [[]],
      recaptchaResponse: [null, Validators.required],
      acceptPrivacyPolicy: [true, [Validators.requiredTrue]],
      acceptNewsletter: [false, [Validators.requiredTrue]]
    });
    this.requestId = Guid.newGuid();
    this.form.controls.requestId.setValue(this.requestId);
  }

  @ViewChild("uploader", { read: ElementRef })
  uploader: ElementRef;

  @ViewChild("styleSelector", { read: ElementRef })
  styleSelector: ElementRef;

  form: FormGroup;

  isFullsize = false;
  submitted = false;
  noStylesErr = false;
  noCbErr = false;
  noImgErr = false;


  hasUploaded = false;
  targetWidth = null;
  captchaMissing = false;

  captchaResolved(res) {
    console.log(res);
    this.form.controls.recaptchaResponse.setValue(res);
  }

  submit() {

    if (!this.form.controls.recaptchaResponse.value) {
      this.captchaMissing = true;
    }
    //Remove when allowing multiple styles
    this.form.controls.styleIds.setValue(["634e44fa-4471-4e40-aa6c-6ffb6ee18928"]);

    this.submitted = true;
    this.noStylesErr = false;
    this.noImgErr = false;

    this.noCbErr = false;
    if (this.form.controls.styleIds.value.length == 0) {
      this.styleSelector.nativeElement.scrollIntoView({ block: 'start', behavior: 'smooth' });

      this.noStylesErr = true;
      return;

    }

    if (!this.hasUploaded) {
      this.uploader.nativeElement.scrollIntoView({ block: 'start', behavior: 'smooth' });

      this.noImgErr = true;
      return;
    }

    if (!this.form.controls.acceptNewsletter.value || !this.form.controls.acceptPrivacyPolicy.value) {
      this.noCbErr = true;
      return;
    }


    if (this.form.valid && this.hasUploaded && this.form.controls.styleIds.value.length != 0) {
      var request = {
        email: this.form.controls.email.value,
        requestId: this.requestId,
        targetWidth: this.targetWidth,
        isFullsize: this.isFullsize,
        recaptchaResult: this.form.controls.recaptchaResponse.value,
        acceptNewsletter: this.form.controls.acceptNewsletter.value,
        styleIds: ["634e44fa-4471-4e40-aa6c-6ffb6ee18928"]// this.form.controls.styleIds.value.map(x => x.styleId)
      };
      console.log(request);
      this.http.post(environment.apiUrl + "/request", request).subscribe(val => {
        this.requestServ.requestId = this.requestId;
        this.router.navigateByUrl(AppRoutes.requestThankYou);
      }, err => {
        alert("Etwas ist schiefgelaufen, probiers bitte nochmal...")
        this.submitted = false;
      })
    }

  }

  ngOnInit(): void {
    console.log(this.form);

  }

}
