import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../auth/user.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { LoginResponse } from 'src/app/auth/data/login-response';
import { AppRoutes } from 'src/app/Routes';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  AppRoutes = AppRoutes;
  loginFormGroup: FormGroup;
  validated = false;
  submitted = false;
  hasLoginError = false;
  errorCode;
  tryAgainInSeconds: number;
  get successfullySubmitted() { return this.validated && this.submitted; }
  constructor(private formBuilder: FormBuilder, private authService: AuthenticationService, public userService: UserService, private router: Router) {
    this.loginFormGroup = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required]
    })
  }

  sub: Subscription;
  onSubmit() {

    this.submitted = true;
    this.hasLoginError = false;

    if (this.loginFormGroup.invalid) {
      this.hasLoginError = true;
      return;
    }

    this.validated = true;

    this.sub = this.authService.login(this.loginFormGroup.controls.email.value, this.loginFormGroup.controls.password.value).subscribe(
      result => { this.hasLoginError = false; this.errorCode = undefined; },
      error => {
        console.log(error);
        this.hasLoginError = true;
        this.errorCode = error.status;

        if (this.errorCode == 429)
          this.tryAgainInSeconds = error.headers.get('retry-after');
      });

  }

  ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
  }



  ngOnInit(): void {
    console.log(this.userService.currentUser);

    if (this.userService.currentUser.isLoggedIn) {
      this.router.navigateByUrl("");
    }

  }

}
