import { Injectable } from '@angular/core';
import { UserRole } from '../auth/data/user-role.enum';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})

export class ApiEndpoints {
  getDeleteStyleUrl(styleId: string): string {
    return this.BaseUrl + "/v1/styles?id=" + styleId;
  }
  BaseUrl = environment.apiUrl;
  getZipSearchUrl(searchterm) { return this.BaseUrl + "/v1/search-zipcode?term=" + searchterm }

  get loginUrl() { return this.BaseUrl + "/v1/user/authenticate"; }
  get registerUrl() { return this.BaseUrl + "/v1/user/register"; }
  getUserInfoUrl(id) { return this.BaseUrl + "/v1/user/" + id + "/info" }
  get isAuthenticatedUrl() { return this.BaseUrl + "/v1/user/authenticated"; }
  get refreshTokenUrl() { return this.BaseUrl + "/v1/user/refresh" }
  get verifyPasswordUrl() { return this.BaseUrl + "/v1/user/verify-password" }


  getChangePasswordRequestUrl() { return this.BaseUrl + "/v1/user/request-password-change" }
  getChangePasswordUrl() { return this.BaseUrl + "/v1/user/change-password" }
  get deleteAccountUrl() { return this.BaseUrl + "/v1/user/delete" }
  get requestUserDataUrl() { return this.BaseUrl + "/v1/user/export-data" }

  getResendActivationEmailUrl() {
    return this.BaseUrl + "/v1/user/email/send-activation";
  }

  get contactUsUrl() { return this.BaseUrl + '/v1/contact' }





  //Adming
  get RenameAllImagesUrl() { return this.BaseUrl + "/v1/images/rename" }


  getDeleteUserUrl(id: string) { return this.BaseUrl + "/v1/user/" + id }

  getGetTranslateablesByCategoryUrl(category: string, locale: string) { return this.BaseUrl + "/v1/translateables?category=" + category + "&locale=" + locale; }
  getGetTranslateableUrl(id, locale: string) { return this.BaseUrl + "/v1/translateable?id=" + id + "&locale=" + locale; }
  getGetTranslablesByIdsUrl(ids: number[]) {
    let res = this.BaseUrl + '/v1/translateables/ids?';
    ids.forEach(x => {
      res += "ids=" + x + "&";
    });
    return res.substr(0, res.length - 1);
  }

  getZipCodeUrl(zipcode: string) { return this.BaseUrl + "/v1/zipcode/" + zipcode; }
  getUploadImageUrl(userId: string) { return this.BaseUrl + "/v1/images?userId=" + userId; }
  getDeleteImageUrl(imageId: string) { return this.BaseUrl + "/v1/images?imageId=" + imageId; }


}
