import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { SiteStateStorageService } from 'src/app/services/site-state-storage.service';

@Component({
  selector: 'app-produktauswahl',
  templateUrl: './produktauswahl.component.html',
  styleUrls: ['./produktauswahl.component.scss']
})
export class ProduktauswahlComponent implements OnInit {

  public workload;
  constructor(public responsive: ResponsiveService, public siteState: SiteStateStorageService) { }

  ngOnInit(): void {
    this.workload = this.siteState.siteState.workload;
    console.log(this.workload);

  }

}
