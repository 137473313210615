import { Injectable, OnDestroy } from '@angular/core';
import { Cookie, CookieCategories, getCookieCategories, I18nItem } from './cookie';
import { Subject, Observable } from 'rxjs';
import { CookieService } from './cookie.service';
import { UserService } from '../auth/user.service';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService implements OnDestroy {




  public onConsentChange: Observable<ConsentChangedEvent>;
  private _onConsentChange = new Subject<ConsentChangedEvent>();

  //cookie key=>cookie allowed
  private cookiesConsent: {};
  private cookiesConsentStorageKey = "cookies_consent";

  constructor(private cookieService: CookieService) {
    this.onConsentChange = this._onConsentChange.asObservable();
    this.cookiesConsent = JSON.parse(localStorage.getItem(this.cookiesConsentStorageKey)) || this.populate();
  }

  populate() {
    var res = {};
    this.cookieService.getAll().forEach(cookie => {
      res[cookie.key] = cookie.category == CookieCategories.NECESSARY;

    });
    return res;
  }


  public getConsentByCategory(): { category: I18nItem, consent: boolean }[] {
    //Consent of a category is determined by consent to all of its cookies
    var cats = getCookieCategories();
    return cats.map(cat => {
      return {
        category: cat,
        consent: !this.cookieService.getByCategory(cat).some(cookie => !this.getCookieConsent(cookie)) && this.cookieService.getByCategory(cat).length != 0
      }
    })
  }

  public getCookieConsents(): CookieConsent[] {
    return Array.from(Object.keys(this.cookiesConsent).map(key => {
      const consent = new CookieConsent();
      consent.cookie = this.cookieService.getByKey(key);
      consent.consented = this.getCookieConsent(consent.cookie);
      return consent;
    }));
  }

  public setConsent(update: ConsentChangedEvent) {
    //If consentAll or declineAll is specified fill the cookies array with all cookies and the specified action
    if (update.consentAll || update.declineAll) {
      let consented = update.consentAll;
      update.cookies = [];

      //Populate the cookies array with all cookies and the specified action
      this.cookieService.getAll().forEach(cookie => {
        const consent = new CookieConsent();
        consent.cookie = cookie;
        consent.consented = consented;
        update.cookies.push(consent);
      });
    }

    update.cookies.forEach(consentUpdate => {
      this.setCookieConsent(consentUpdate.cookie, consentUpdate.consented);
    })


    this._onConsentChange.next(update);

  }

  public getCookieConsent(cookie: Cookie) {

    if (cookie.category == CookieCategories.NECESSARY)
      return true;

    var consent = this.cookiesConsent[cookie.key];

    if (consent == null)
      return false;

    return consent;

  }

  private setCookieConsent(cookie: Cookie, consent: boolean) {
    if (cookie.category != CookieCategories.NECESSARY) {
      this.cookiesConsent[cookie.key] = consent;
      this.updateConsent();
    }
  }
  private updateConsent() {
    localStorage.setItem(this.cookiesConsentStorageKey, JSON.stringify(this.cookiesConsent));
  }


  ngOnDestroy(): void {
    this._onConsentChange.complete();
  }

}
export class ConsentChangedEvent {
  constructor(
    //Ignored if either consentAll or declineAll are set
    public cookies: CookieConsent[],
    public consentAll: boolean = false,
    public declineAll: boolean = false,


  ) { }

}

export class CookieConsent {

  cookie: Cookie;
  consented: boolean;
}
