import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminOutletComponent } from './admin-outlet/admin-outlet.component';
import { AdminOnlyGuardGuard } from '../auth/guards/admin-only-guard.guard';
import { StyleSettingsComponent } from './style-settings/style-settings.component';
import { AdminResultPreviewComponent } from './admin-result-preview/admin-result-preview.component';
import { CreateHdComponent } from './create-hd/create-hd.component';


const routes: Routes = [
  {
    path: "admin",
    component: AdminOutletComponent,
    canActivate: [AdminOnlyGuardGuard],
    children: [
      {
        path: "",
        pathMatch: 'full',
        redirectTo: "styles"
      },
      {
        path: "styles",
        component: StyleSettingsComponent,
      },
      {
        path: "result",
        component: AdminResultPreviewComponent
      },
      {
        path: "hd",
        component: CreateHdComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
