import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserRole } from './data/user-role.enum';
import { UserRepositoryService } from './user-repository/user-repository.service';
import { UserInfo, GetLoggedOutUserInfo } from './data/user-info';
import { HttpClient } from '@angular/common/http';
import { ApiEndpoints } from '../api-interface/api-endpoints';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy, OnInit {

  constructor(private repo: UserRepositoryService, private http: HttpClient, private endpoints: ApiEndpoints) {
  }

  private _currentUser: UserInfo;
  public keepLoggedIn = true;


  get currentUser(): UserInfo {

    //Load the logged in user from local storage if it isnt loaded already
    if (this._currentUser == undefined) {
      this._currentUser = this.repo.getCurrentUser();

      //If the local storage doesnt hold any UserInfo save one for a logged out user (UserInfo.isLoggedIn = false)
      if (this._currentUser == undefined) {
        this.currentUser = GetLoggedOutUserInfo();
      }
    }

    return this._currentUser;
  }
  set currentUser(user: UserInfo) {
    this._currentUser = user;
    this.repo.setCurrentUser(user, !this.keepLoggedIn);
  }

  ngOnInit(): void {

  }

  /**
   * Updates the UserInfo object, that was loaded when the user logged in
   */
  public updateUserInfo() {
    this.http.get<UserInfo>(this.endpoints.getUserInfoUrl(this.currentUser.id)).pipe(shareReplay()).subscribe(updatedUserInfo => {

      Object.keys(updatedUserInfo).forEach(key => {
        if (this.currentUser.isLoggedIn) {
          let user = this.currentUser;
          user[key] = updatedUserInfo[key];
          this.currentUser = user;
        }
      });


    });
  }

  public deleteAccount() {
    return this.http.post(this.endpoints.deleteAccountUrl, {}).pipe(shareReplay());
  }

  //For easy access through binding from html files


  get hasUserCustomerRole(): boolean {
    return this.currentUser.isLoggedIn && this.currentUser.role == UserRole.Customer;
  }

  get hasUserAdminRole(): boolean {
    return this.currentUser.isLoggedIn && this.currentUser.role == UserRole.Admin;
  }
  get hasUserReadRole(): boolean {
    return this.currentUser.isLoggedIn && this.currentUser.role == UserRole.Read;
  }

  get hasUserSupportRole(): boolean {
    return this.currentUser.isLoggedIn && this.currentUser.role == UserRole.Support;
  }

  ngOnDestroy() {

  }
}


