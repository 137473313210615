import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomizerRoutingModule } from './customizer-routing.module';
import { CustomizerPageComponent } from './customizer-page/customizer-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FormComponentsModule } from '../form-components/form-components.module';
import { PageComponentsModule } from '../page-components/page-components.module';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { CustomizerControlsComponent } from './customizer-controls/customizer-controls.component';
import { CustomizerControlsPageComponent } from './customizer-controls-page/customizer-controls-page.component';
import { ShopifyBuyButtonComponent } from './shopify-buy-button/shopify-buy-button.component';
import { CustomizerControlsScrollComponent } from './customizer-controls-scroll/customizer-controls-scroll.component';


@NgModule({
  declarations: [CustomizerPageComponent,
    CustomizerControlsComponent,
    CustomizerControlsPageComponent,
    ShopifyBuyButtonComponent,
    CustomizerControlsScrollComponent,],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    FormComponentsModule,
    PageComponentsModule,
    FormElementsModule,
    CustomizerRoutingModule
  ]
})
export class CustomizerModule { }
