import { Injectable } from '@angular/core';
import { min } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PosterService {

  constructor() { }


}

export function getPosterMinPrice() {
  let minprice = Number.MAX_VALUE;
  posterVariations.forEach(x => {
    if (x.price < minprice)
      minprice = x.price;
  })
  return minprice;
}

export const posterVariations: ProductVariation[] = [
  { type: "Poster", format: "", size: "40cm x 30cm", price: 20 },
  { type: "Poster", format: "", size: "60cm x 45cm", price: 30 },
  { type: "Poster", format: "", size: "80cm x 60cm", price: 60 }
]

export const canvasVariations: ProductVariation[] = [
  { type: "Leinwand", format: "", size: "40cm x 30cm", price: 50 },
  { type: "Leinwand", format: "", size: "60cm x 45cm", price: 60 },
  { type: "Leinwand", format: "", size: "80cm x 60cm", price: 110 }
]

export const hdDownload: ProductVariation = {
  type: "HD-Download",
  size: "ca. 24MP",
  price: 10,
  isDownload: true
}

export const sdDownload: ProductVariation = {
  type: "SD-Download",
  size: "ca. 1MP",
  price: 0,
  isDownload: true
}

export const poster: ProductVariation = {
  type: "Poster"
}
export const canvas: ProductVariation = {
  type: "Leinwand"
}

export const sdDownloadResolution = "512x256";

export interface ProductVariation {
  type: string,
  format?: string,
  size?: string,
  price?: number,
  isDownload?: boolean,
}
