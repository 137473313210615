import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutes } from '../Routes';
import { CustomizerPageComponent } from './customizer-page/customizer-page.component';


const routes: Routes = [
  { path: AppRoutes.customizer, component: CustomizerPageComponent, data: { hideNavbar: true } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomizerRoutingModule { }
