import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../auth/user.service';
import { RegistrationControllerFactoryService } from '../../registration-controller-factory.service';
import { RegistrationController } from '../../registration-controller';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { AppRoutes } from 'src/app/Routes';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public controller: RegistrationController;

  public AppRoutes = AppRoutes;

  @ViewChild('email') emailField;

  constructor(public controllerFactory: RegistrationControllerFactoryService,
    public userService: UserService,
    public responsive: ResponsiveService) {
    this.controller = controllerFactory.getRegistrationController();
  }


  onSubmit() {
    this.controller.onSubmit();
  }

  ngOnInit() {
    this.controller.ngOnInit();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
    var element = <any>document.querySelector('input[placeholder="Email"]');
    if (element) {
      setTimeout(() => element.focus(), 500);
    }
  }

}
