import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ShopifyService } from '../shopify.service';
declare var ShopifyBuy;
@Component({
  selector: 'app-shopify-buy-button',
  templateUrl: './shopify-buy-button.component.html',
  styleUrls: ['./shopify-buy-button.component.css']
})
export class ShopifyBuyButtonComponent implements OnInit, OnChanges {

  @Input()
  id = '5276772761756';

  @Input()
  variant;

  @Input()
  productComponent = '1595944478483';

  @Input()
  userEmail: string;

  @Input()
  requestId: string;
  @Input()
  workloadId: string;
  checkoutUrl;
  windowAny: any;
  constructor(private shopify: ShopifyService) {
    this.windowAny = window;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.productComponent || changes.requestId || changes.userEmail) {
      this.shopify.createCheckout(this.userEmail, this.requestId, this.workloadId, this.productComponent, this.variant).then(x => {
        this.checkoutUrl = x;
      });
    }
  }

  scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

  loadScript() {

    var script = document.createElement('script');
    script.async = true;
    script.src = this.scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = () => { this.ShopifyBuyInit() };

  }

  ngOnInit(): void {


  }

  ShopifyBuyInit() {

    var client = ShopifyBuy.buildClient({
      domain: 'artai.myshopify.com',
      storefrontAccessToken: '92ea766c03c58954afa7e54d51c06fbc',
    });
    ShopifyBuy.UI.onReady(client).then((ui) => {
      ui.createComponent('product', {
        id: this.id,
        node: document.getElementById('product-component-' + this.productComponent),
        moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
        options: {
          "product": {
            "styles": {
              "product": {
                "@media (min-width: 601px)": {
                  "max-width": "calc(25% - 20px)",
                  "margin-left": "20px",
                  "margin-bottom": "50px"
                }
              },
              "title": {
                "font-family": "Open Sans, sans-serif",
                "font-weight": "normal",
                "color": "#555555"
              },
              "button": {
                "font-family": "Open Sans, sans-serif",
                ":hover": {
                  "background-color": "#299577"
                },
                "background-color": "#2ea684",
                ":focus": {
                  "background-color": "#299577"
                },
                "border-radius": "5px"
              },
              "price": {
                "font-family": "Open Sans, sans-serif"
              },
              "compareAt": {
                "font-family": "Open Sans, sans-serif"
              },
              "unitPrice": {
                "font-family": "Open Sans, sans-serif"
              }
            },
            "buttonDestination": "checkout",
            "contents": {
              "img": false,
              "title": false,
              "price": false,
              "options": false
            },
            "text": {
              "button": "Buy now"
            },
            "googleFonts": [
              "Open Sans"
            ]
          },
          "productSet": {
            "styles": {
              "products": {
                "@media (min-width: 601px)": {
                  "margin-left": "-20px"
                }
              }
            }
          },
          "modalProduct": {
            "contents": {
              "img": false,
              "imgWithCarousel": true,
              "button": false,
              "buttonWithQuantity": true
            },
            "styles": {
              "product": {
                "@media (min-width: 601px)": {
                  "max-width": "100%",
                  "margin-left": "0px",
                  "margin-bottom": "0px"
                }
              },
              "button": {
                "font-family": "Open Sans, sans-serif",
                ":hover": {
                  "background-color": "#299577"
                },
                "background-color": "#2ea684",
                ":focus": {
                  "background-color": "#299577"
                },
                "border-radius": "5px"
              }
            },
            "googleFonts": [
              "Open Sans"
            ],
            "text": {
              "button": "Add to cart"
            }
          },
          "option": {
            "styles": {
              "label": {
                "font-family": "Open Sans, sans-serif"
              },
              "select": {
                "font-family": "Open Sans, sans-serif"
              }
            },
            "googleFonts": [
              "Open Sans"
            ]
          },
          "cart": {
            "styles": {
              "button": {
                "font-family": "Open Sans, sans-serif",
                ":hover": {
                  "background-color": "#299577"
                },
                "background-color": "#2ea684",
                ":focus": {
                  "background-color": "#299577"
                },
                "border-radius": "5px"
              }
            },
            "text": {
              "total": "Subtotal",
              "button": "Checkout"
            },
            "popup": false,
            "googleFonts": [
              "Open Sans"
            ]
          },
          "toggle": {
            "styles": {
              "toggle": {
                "font-family": "Open Sans, sans-serif",
                "background-color": "#2ea684",
                ":hover": {
                  "background-color": "#299577"
                },
                ":focus": {
                  "background-color": "#299577"
                }
              }
            },
            "googleFonts": [
              "Open Sans"
            ]
          }
        },
      });
    });
  }

}
