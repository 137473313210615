import { Injectable } from '@angular/core';
import * as Client from 'shopify-buy';

@Injectable({
  providedIn: 'root'
})
export class ShopifyService {
  storefrontToken = "e5ec294a667bd190b9f2ec48cdd448c2";
  client: Client.Client;
  constructor() {
    this.client = Client.buildClient({
      domain: "artai.myshopify.com",
      storefrontAccessToken: this.storefrontToken,
    });
  }

  async createCheckout(email, requestId, workloadId, itemId, variantId) {

    var item = await this.client.product.fetch(btoa(`gid://shopify/Product/${itemId}`));
    console.log(item);
    var requestVariantId = variantId ? btoa(`gid://shopify/ProductVariant/${variantId}`) : item.variants[0].id;

    var items: any[] = [{
      quantity: 1,
      variantId: requestVariantId,
      // customAttributes: [{ key: "requestId", value: requestId }]
    }]
    var checkout = await this.client.checkout.create();

    checkout = await this.client.checkout.addLineItems(checkout.id, (<Client.LineItem[]>items));
    checkout = await (<any>this.client.checkout).updateAttributes(checkout.id, {
      customAttributes: [
        { key: "requestId", value: requestId }, { key: "HD Version Generieren", value: `https://artai.de/admin/hd?workloadId=${workloadId}` }
      ]
    })
    console.log(checkout);

    return (<any>checkout).webUrl;
  }
}
