import { Directive, OnInit, AfterViewInit, Renderer2, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: 'input[prependAppend]'
})
export class InputPrependPostpendDirective implements AfterViewInit {
  @Input()
  prepend: string;
  @Input()
  prependFaIconClass: string;


  @Input()
  append: string;
  @Input()
  appendFaIconClass: string;

  wrapperElement;

  constructor(private _renderer: Renderer2, private _el: ElementRef) {

  }

  ngAfterViewInit() {
    if (this.prepend || this.prependFaIconClass || this.append || this.appendFaIconClass) {
      this.createWrapperAndRemoveInput();

      this.addPrepend();
      // Re-add the input inside the wrapper
      this._renderer.appendChild(this.wrapperElement, this._el.nativeElement);

      this.addApppend();
    }

  }

  addPrepend() {
    if (this.prepend || this.prependFaIconClass) {
      let prepend = this._renderer.createElement("div")
      this._renderer.addClass(prepend, "input-group-prepend");
      this._renderer.appendChild(this.wrapperElement, prepend);
      let prependContent = this._renderer.createElement("span")
      this._renderer.addClass(prependContent, "input-group-text");
      this._renderer.addClass(prependContent, "form-control");

      if (this.prepend)
        this._renderer.appendChild(prependContent, this._renderer.createText(this.prepend))
      else if (this.prependFaIconClass) {
        let icon = this._renderer.createElement("i");
        this._renderer.addClass(icon, "fa");
        this._renderer.addClass(icon, this.prependFaIconClass);
        this._renderer.appendChild(prependContent, icon);
      }


      this._renderer.appendChild(prepend, prependContent);
    }
  }

  addApppend() {
    if (this.append || this.appendFaIconClass) {
      let append = this._renderer.createElement("div")
      this._renderer.addClass(append, "input-group-append");
      this._renderer.appendChild(this.wrapperElement, append);
      let appendContent = this._renderer.createElement("span")
      this._renderer.addClass(appendContent, "input-group-text");
      this._renderer.addClass(appendContent, "form-control");

      if (this.append)
        this._renderer.appendChild(appendContent, this._renderer.createText(this.append))
      else if (this.appendFaIconClass) {
        let icon = this._renderer.createElement("i");
        this._renderer.addClass(icon, "fa");
        this._renderer.addClass(icon, this.appendFaIconClass);
        this._renderer.appendChild(appendContent, icon);
      }


      this._renderer.appendChild(append, appendContent);
    }
  }


  createWrapperAndRemoveInput() {
    // Get parent of the original input element
    var parent = this._el.nativeElement.parentNode;

    // Create a div
    this.wrapperElement = this._renderer.createElement("div");

    // Add class "input-wrapper"
    this._renderer.addClass(this.wrapperElement, "input-group");

    // Add the div, just before the input
    this._renderer.insertBefore(parent, this.wrapperElement, this._el.nativeElement);

    // Remove the input
    this._renderer.removeChild(parent, this._el.nativeElement);

    // Remove the directive attribute (not really necessary, but just to be clean)
    this._renderer.removeAttribute(this._el.nativeElement, "inputWrapper");


  }

  @HostListener('focus', ['$event']) onFocus(e) {
    if (this.wrapperElement)
      this._renderer.addClass(this.wrapperElement, "input-group-focus");
  }

  @HostListener('blur', ['$event']) onBlur(e) {
    if (this.wrapperElement)
      this._renderer.removeClass(this.wrapperElement, "input-group-focus");
  }
}
