import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextFormFieldComponent } from './text-form-field/text-form-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZipCodeFormFieldComponent } from './zip-code-form-field/zip-code-form-field.component';
import { ToggledTextFormFieldComponent } from './toggled-text-form-field/toggled-text-form-field.component';
import { ApiDatasourceCheckboxListComponent } from './api-datasource-checkbox-list/api-datasource-checkbox-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiDatasourceRadioGroupComponent } from './api-datasource-radio-group/api-datasource-radio-group.component';
import { TranslateableSelectComponent } from './translateable-select/translateable-select.component';
import { NouisliderModule } from 'ng2-nouislider';
import { ApiDatasourceCheckboxGroupComponent } from './api-datasource-checkbox-group/api-datasource-checkbox-group.component';
import { TypeaheadTextFormFieldComponent } from './typeahead-text-form-field/typeahead-text-form-field.component';
import { NumberOnlyDirective } from './text-form-field/number-only.directive';
import { RouterModule } from '@angular/router';
import { SimpleComponentsModule } from '../simple-components/simple-components.module';
import { NoSpecialCharsDirective } from './text-form-field/no-special-chars.directive';
import { InputPrependPostpendDirective } from './text-form-field/input-prepend-postpend.directive';
import { PopupBaseModule } from '../popup-base/popup-base.module';
import { CitySelectorComponent } from './city-selector/city-selector.component';
import { InvisibleSlideComponent } from './invisible-slide/invisible-slide.component';
import { FileUploadModule } from 'ng2-file-upload';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CheckboxComponent } from './checkbox/checkbox.component';


@NgModule({
  declarations: [
    TextFormFieldComponent,
    ZipCodeFormFieldComponent,
    ToggledTextFormFieldComponent,
    ApiDatasourceCheckboxListComponent,
    NoSpecialCharsDirective,
    ApiDatasourceRadioGroupComponent,
    TranslateableSelectComponent,
    ApiDatasourceCheckboxGroupComponent,
    TypeaheadTextFormFieldComponent,
    NumberOnlyDirective,
    InputPrependPostpendDirective,
    CitySelectorComponent,
    InvisibleSlideComponent,
    CheckboxComponent,
  ],
  imports: [
    PopupBaseModule,
    CommonModule,
    FormsModule,
    FileUploadModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    SimpleComponentsModule,
    ReactiveFormsModule,
    NouisliderModule,
  ],
  exports: [
    TextFormFieldComponent,
    ZipCodeFormFieldComponent,
    ToggledTextFormFieldComponent,
    ApiDatasourceCheckboxListComponent,
    ApiDatasourceRadioGroupComponent,
    TranslateableSelectComponent,
    ApiDatasourceCheckboxGroupComponent,
    TypeaheadTextFormFieldComponent,
    NumberOnlyDirective,
    NoSpecialCharsDirective,
    CitySelectorComponent,
    CheckboxComponent,
    InvisibleSlideComponent,
  ]
})
export class FormComponentsModule { }
