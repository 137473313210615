import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
//info icon that shows tooltip on hover
export class InfoComponent implements OnInit {

  @Input()
  text: string;

  @Input()
  placement: "top" | "bottom" | "left" | "right";

  constructor() { }

  iconClass = "fa-question-circle-o"

  ngOnInit() {
  }

}
