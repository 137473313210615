import { Component, OnInit } from '@angular/core';
import { RequestClient } from 'src/app/api-interface/clients';
import { RequestService } from 'src/app/services/request.service';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/Routes';

@Component({
  selector: 'app-request-thanks',
  templateUrl: './request-thanks.component.html',
  styleUrls: ['./request-thanks.component.scss']
})
export class RequestThanksComponent implements OnInit {

  requestId;
  constructor(private requestController: RequestClient, private requestServ: RequestService, private router: Router) { }

  ngOnInit(): void {
    this.requestId = this.requestServ.requestId;
    this.refreshResults();
    console.time("Request")
  }

  refreshResults() {
    this.requestController.getFinishedRequest(this.requestId).subscribe(val => {
      console.log("Request finished");
      console.timeEnd("Request");
      this.router.navigateByUrl(AppRoutes.customizer + "?id=" + this.requestId);
    },
      err => {
        console.log("Request not yet finished");

        setTimeout(() => {
          this.refreshResults();
        }, 5000);
      })
  }

}
