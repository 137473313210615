import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-outlet',
  templateUrl: './admin-outlet.component.html',
  styleUrls: ['./admin-outlet.component.css']
})
export class AdminOutletComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  onActivate() {
    window.scroll(0, 0);

  }
}
