import { Component, OnInit } from '@angular/core';
import { StyleDto, Style, StylesAdminClient } from 'src/app/api-interface/clients';
import { Guid } from 'src/app/util/guid';

@Component({
  selector: 'app-style-editor',
  templateUrl: './style-editor.component.html',
  styleUrls: ['./style-editor.component.css']
})
export class StyleEditorComponent implements OnInit {

  constructor(private styleServ: StylesAdminClient) { }

  styles: Style[] = [];

  openStyleEditorIndex = null;

  addStyle() {

    this.styleServ.getDefaultStyle().subscribe(val => {
      console.log(val);

      this.styles.push(val);
    })

  }

  getImageUrl(style) {
    return "https://artai-styles.s3.eu-central-1.amazonaws.com/" + style.styleId + "-md.jpg"
  }

  ngOnInit(): void {
    this.styleServ.getAllStyles().subscribe(val => {

      this.styles = <any>val as Style[];
    })

  }

}
