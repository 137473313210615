import { Component, OnInit } from '@angular/core';
import { ModalBaseComponent } from 'src/app/popup-base/modal-base/modal-base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/auth/user.service';

@Component({
  selector: 'app-email-verify-landing-page',
  templateUrl: './email-verify-landing-page.component.html',
  styleUrls: ['./email-verify-landing-page.component.scss']
})
export class EmailVerifyLandingPageComponent implements OnInit {

  constructor(private userService: UserService) {
  }

  ngOnInit() {
  }

}
