import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { share, shareReplay, catchError, map } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { ApiEndpoints } from 'src/app/api-interface/api-endpoints';
import { ZipCode } from './zip-code';

@Injectable({
  providedIn: 'root'
})
export class ZipCodeService {

  constructor(private http: HttpClient, private endpoints: ApiEndpoints) { }

  public getZipCode(plz: string): Observable<ZipCode> {
    return this.http.get<ZipCode>(this.endpoints.getZipCodeUrl(plz)).pipe(shareReplay());
  }


}

