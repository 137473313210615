import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { AppRoutes } from 'src/app/Routes';
import { ModalBaseComponent } from 'src/app/popup-base/modal-base/modal-base.component';
import { CookieConsentService } from '../cookie-consent.service';
import { tap } from 'rxjs/operators';
import { CookieEditorComponent } from '../cookie-editor/cookie-editor.component';

@Component({
  selector: 'app-cookie-editor-popup',
  templateUrl: './cookie-editor-popup.component.html',
  styleUrls: ['./cookie-editor-popup.component.scss']
})
export class CookieEditorPopupComponent implements OnInit {
  AppRoutes = AppRoutes;

  @Output() onModalClose = new EventEmitter<any>();
  @Input() title: string;
  @Input() subtitle: string;
  @Input() showCross: boolean = true;
  @Input() size: "lg" | "sm" = "lg";
  @ViewChild(ModalBaseComponent)
  modal: ModalBaseComponent;
  @ViewChild(CookieEditorComponent)
  cookieEditor: CookieEditorComponent;


  constructor(private cookieConsent: CookieConsentService) {

    cookieConsent.onConsentChange.pipe(tap(x => {
      if (this.modal)
        this.modal.close()
    }
    )).subscribe();
  }


  public open() {
    this.cookieEditor.loadConsent();
    this.modal.open();
  }

  ngOnInit() {
  }

}
