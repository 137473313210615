import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { ClipperComponent } from './clipper/clipper.component';
import { PageClipperComponent } from './page-clipper/page-clipper.component';
import { StaffelComponent } from './staffel/staffel.component';
import { ImageCardComponent } from './image-card/image-card.component';
import { InvisibleSliderComponent } from './invisible-slider/invisible-slider.component';
import { LoadingComponent } from './loading/loading.component';
import { ExampleViewerComponent } from './example-viewer/example-viewer.component';
import { MagnifyExampleViewerComponent } from './magnify-example-viewer/magnify-example-viewer.component';
import { ZoomableImageCardComponent } from './zoomable-image-card/zoomable-image-card.component';



@NgModule({
  declarations: [ImageSliderComponent,
    ClipperComponent,
    PageClipperComponent,
    StaffelComponent,
    ImageCardComponent,
    InvisibleSliderComponent,
    LoadingComponent,
    ExampleViewerComponent,
    MagnifyExampleViewerComponent,
    ZoomableImageCardComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    NouisliderModule,
  ],
  exports: [ImageSliderComponent, ClipperComponent,
    PageClipperComponent,
    StaffelComponent,
    ZoomableImageCardComponent,
    ImageCardComponent,
    InvisibleSliderComponent,
    ExampleViewerComponent,
    LoadingComponent,
  ]
})
export class PageComponentsModule { }
