import { Component, OnInit } from '@angular/core';
import { CookieConsentService, ConsentChangedEvent } from '../cookie-consent.service';
import { FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Cookies } from '../cookie.service';
import { StorageService } from 'src/app/services/storage.service';
import { CookieCategories, Cookie, I18nItem } from '../cookie';
import { I18nService } from 'src/app/services/i18n.service';
import { SiteStateStorageService } from 'src/app/services/site-state-storage.service';

@Component({
  selector: 'app-cookie-editor',
  templateUrl: './cookie-editor.component.html',
  styleUrls: ['./cookie-editor.component.scss']
})
export class CookieEditorComponent implements OnInit {



  array;
  categories: I18nItem[];
  showTable;


  constructor(private cookieConsent: CookieConsentService,
    private storageService: StorageService,
    private i18n: I18nService,
    private siteState: SiteStateStorageService) { }

  ngOnInit() {
    this.loadConsent();
  }

  public loadConsent() {
    this.array = [];
    this.categories = Object.keys(CookieCategories).map(key => CookieCategories[key]);
    var consentByCategory = this.cookieConsent.getConsentByCategory();
    this.categories.forEach(cat => {
      cat.load(this.i18n)
      this.array.push({
        category: cat,
        consent: cat == CookieCategories.NECESSARY || consentByCategory.filter(x => x.category == cat)[0].consent,
        disabled: cat == CookieCategories.NECESSARY,
      });
    });
  }

  acceptAll() {
    this.siteState.siteState.hasSeenConsentBar = true;
    this.siteState.notifySiteStateUpdated();
    this.cookieConsent.setConsent(new ConsentChangedEvent(null, true))
    this.loadConsent();
  }

  rejectAll() {
    this.siteState.siteState.hasSeenConsentBar = true;
    this.siteState.notifySiteStateUpdated();
    this.cookieConsent.setConsent(new ConsentChangedEvent(null, false, true))
    this.loadConsent();

  }

  save() {
    this.siteState.siteState.hasSeenConsentBar = true;
    this.siteState.notifySiteStateUpdated();

    this.cookieConsent.setConsent(new ConsentChangedEvent(this.array))


  }
}
