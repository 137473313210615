import { Component, OnInit, EventEmitter, Output, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'app-floating-action-button',
  templateUrl: './floating-action-button.component.html',
  styleUrls: ['./floating-action-button.component.scss']
})
export class FloatingActionButtonComponent implements OnInit {



  // Hides the fab if the passsed element is visible
  @Input()
  hideButtonWhenVisible: any;

  @Input()
  position: "center" | "right" = "right";

  constructor() { }

  ngOnInit() {
  }


  showDirectEnquiryElementInViewport() {
    if (!this.hideButtonWhenVisible || !this.hideButtonWhenVisible.getBoundingClientRect)
      return false;

    var rect = this.hideButtonWhenVisible.getBoundingClientRect();


    var res = (
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth) ||
      rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right >= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );

    return res;
  }

}
