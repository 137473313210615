import { Injectable, OnDestroy } from '@angular/core';
import { LoginResponse } from './data/login-response';
import { UserRole } from './data/user-role.enum';
import { Observable, ConnectableObservable, Subject } from 'rxjs';
import { shareReplay, tap, publishReplay } from 'rxjs/operators';
import { ApiEndpoints } from '../api-interface/api-endpoints';
import { UserService } from './user.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserInfo, GetLoggedOutUserInfo } from './data/user-info';
import { AppRoutes } from '../Routes';
import { LoginRedirectService } from './login-redirect.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

  constructor(private endpoints: ApiEndpoints,
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
    private redirect: LoginRedirectService) {

    this.onLoggedIn = this._onLoggedIn.asObservable();
    this.onRegistered = this._onRegistered.asObservable();
    this.onAuthenticated = this._onAuthenticated.asObservable();

    this.onAuthenticated.subscribe(x => {
      redirect.updateAfterLoginRoute();
    });
  }

  public onLoggedIn: Observable<LoginResponse>;
  private _onLoggedIn = new Subject<LoginResponse>();

  //Fires on both register and login
  public onAuthenticated: Observable<LoginResponse>;
  private _onAuthenticated = new Subject<LoginResponse>();

  public onRegistered: Observable<LoginResponse>;
  private _onRegistered = new Subject<LoginResponse>();

  public get isLoggedIn() {
    return this.userService.currentUser.isLoggedIn;
  }

  verifyPassword(password: string): Observable<any> {
    return this.http.post(this.endpoints.verifyPasswordUrl, { password: password }).pipe(shareReplay());
  }

  login(email: string, password: string): Observable<HttpResponse<LoginResponse>> {

    //Post to server
    let obs: ConnectableObservable<HttpResponse<LoginResponse>> = this.http.post<LoginResponse>(this.endpoints.loginUrl,
      {
        email: email,
        password: password
      },
      { observe: "response" }
    ).pipe(

      //Specify how to process the responce, once it comes
      tap(
        res => {
          this.notifyLoggedIn(res);
          this._onAuthenticated.next(res.body);
          this._onLoggedIn.next(res.body);

          let redirectRoute = this.redirect.afterLoginRedirectRoute;
          setTimeout(() => {
            this.router.navigateByUrl(this.redirect.afterLoginRedirectRoute);
          }, 10);
        },
        err => { console.log("Login error:", err); }
      ),
      publishReplay()) as ConnectableObservable<HttpResponse<LoginResponse>>;
    //Make the observable start emmitting, thus sending the request
    obs.connect();
    //Return the observable, so the response can be processed elsewhere as well (for example to display the result in a component)
    return obs;
  }

  register(email: string, password: string, role: UserRole): Observable<HttpResponse<LoginResponse>> {

    //Post to server
    let obs: Observable<HttpResponse<LoginResponse>> = this.http.post<LoginResponse>(this.endpoints.registerUrl, {
      email: email,
      password: password,
      role: role
    }, { observe: "response" }).pipe(
      shareReplay());
    obs.subscribe(
      res => {
        this.notifyLoggedIn(res, this.getPostRegisterRedirectUrlForRole(role));
        this._onAuthenticated.next(res.body);
        this._onRegistered.next(res.body);

      },
      err => { console.log("Something went wrong while registering", err) })
    //Return the observable, so the response can be processed elsewhere as well (for example to display the result in a component)
    return obs;
  }

  getPostRegisterRedirectUrlForRole(role: UserRole): string {

    //Add role dependant redirects here
    // if (role == UserRole.Admin) {
    //   return AppRoutes.admin.setup;
    // }
    return null;
  }

  refreshToken() {

    if (this.userService.currentUser.isLoggedIn && this.userService.currentUser.loginToken.jwtTokenValidUntil < Date.now() / 1000) {

      let obs: ConnectableObservable<HttpResponse<LoginResponse>> = this.http.post<HttpResponse<LoginResponse>>(this.endpoints.refreshTokenUrl, {
        jwtToken: this.userService.currentUser.loginToken.jwtToken,
        refreshToken: this.userService.currentUser.loginToken.refreshToken
      }).pipe(

        tap(
          res => { this.notifyLoggedIn(res); },
          () => { this.notifyLoggedOut(); }
        ),
        publishReplay()
      ) as ConnectableObservable<HttpResponse<LoginResponse>>;
      obs.connect();
      return obs;
    }
  }

  public notifyLoggedIn(result: HttpResponse<LoginResponse>, redirectUrl: any = null) {

    let userInfo: UserInfo = {
      email: result.body.email,
      loginToken: result.body.loginToken,
      role: result.body.role,
      isTestUser: result.body.isTestUser,
      isEmailVerified: result.body.isEmailVerified,
      id: result.body.id,
      isLoggedIn: true
    };
    this.userService.currentUser = userInfo;

    if (redirectUrl)
      this.router.navigateByUrl(redirectUrl);
  }


  public logout() {
    //TODO:Send logout to server
    this.notifyLoggedOut();
  }

  //Handles the client side representation of the change to logged out state
  public notifyLoggedOut() {
    this.userService.currentUser = GetLoggedOutUserInfo();
    this.router.navigateByUrl("");
  }

  validateLoginSession() {
    if ((this.userService.currentUser.isLoggedIn && this.userService.currentUser.loginToken.jwtTokenValidUntil < Date.now() / 1000) &&
      this.userService.currentUser.loginToken.refreshTokenValidUntil < Date.now() / 1000) {
      this.notifyLoggedOut();
    }
  }

  ngOnDestroy(): void {
    this._onAuthenticated.complete();
    this._onLoggedIn.complete();
    this._onRegistered.complete();
  }

}
