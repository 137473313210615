import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-page-clipper',
  templateUrl: './page-clipper.component.html',
  styleUrls: ['./page-clipper.component.scss']
})
export class PageClipperComponent implements OnInit, OnChanges, AfterViewInit {

  /**
   * Clipping from 0-100
   * 0: no clipping
   */
  @Input()
  progress: number;

  @Input()
  showDivider: boolean = true;

  @ViewChild("clip")
  clip: ElementRef;

  @ViewChild("unclipped")
  unclipped: ElementRef;

  @ViewChild("divider")
  divider: ElementRef;

  @ViewChild("clipcontent")
  clipcontent: ElementRef;
  class = "";

  constructor(
    private renderer: Renderer2,
  ) { }
  ngAfterViewInit(): void {
    this.updatePrgs();

    setTimeout(() => {
      this.sizeClipContent();
      setTimeout(() => {
        this.sizeClipContent();
        setTimeout(() => {
          this.sizeClipContent();
          setTimeout(() => {
            this.sizeClipContent();

          }, 100);
        }, 100);
      }, 100);

    }, 100);
  }
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.progress && this.clip)
      this.updatePrgs();

  }

  onResize(e) {
    this.sizeClipContent();
  }

  updatePrgs() {
    // this.class = "clip" + Math.round(this.progress);
    this.renderer.setStyle(this.clip.nativeElement, 'width', `${this.progress}%`);
    if (this.showDivider)
      this.renderer.setStyle(this.divider.nativeElement, 'left', `${this.progress}%`)

  }
  sizeClipContent() {
    let style = getComputedStyle(this.unclipped.nativeElement);


    this.renderer.setStyle(this.clipcontent.nativeElement, 'width', `${style.width}`);
    this.renderer.setStyle(this.clipcontent.nativeElement, 'height', `${style.height}`);
    this.renderer.setStyle(this.divider.nativeElement, 'height', `${style.height}`);

    this.renderer.setStyle(this.clip.nativeElement, 'height', `${style.height}`);

  }

  ngOnInit(): void {
  }

}
