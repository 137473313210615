import { Component, OnInit } from '@angular/core';
import { AppRoutes } from 'src/app/Routes';

@Component({
  selector: 'app-admin-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrls: ['./admin-navigation.component.css']
})
export class AdminNavigationComponent implements OnInit {

  constructor() { }

  results = AppRoutes.admin.results;
  styles = AppRoutes.admin.styles;
  tweaker = AppRoutes.admin.styleTweaker;

  ngOnInit(): void {
  }

}
