import { Component, OnInit, forwardRef } from '@angular/core';
import { BaseControlValueAccessor } from 'src/app/form-components/base-control-value-accessor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-invisible-slider',
  templateUrl: './invisible-slider.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InvisibleSliderComponent),
      multi: true
    }
  ],
  styleUrls: ['./invisible-slider.component.scss']
})
export class InvisibleSliderComponent extends BaseControlValueAccessor implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
