import { Component, OnInit } from '@angular/core';
import { timer, interval } from 'rxjs';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/Routes';

@Component({
  selector: 'app-error-landing',
  templateUrl: './error-landing.component.html',
  styleUrls: ['./error-landing.component.scss']
})
export class ErrorLandingComponent implements OnInit {

  constructor(private router: Router) { }
  timeToRedirect = 10000;

  ngOnInit() {
  }

  goToHome() {
    this.router.navigateByUrl(AppRoutes.home);
  }
}
