import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminClient, FinishedWorkloadDto, StylesAdminClient } from 'src/app/api-interface/clients';

@Component({
  selector: 'app-create-hd',
  templateUrl: './create-hd.component.html',
  styleUrls: ['./create-hd.component.css']
})
export class CreateHdComponent implements OnInit {

  workloadId: string;
  workload: FinishedWorkloadDto;
  startHdGenSuccess: boolean;
  constructor(private route: ActivatedRoute, private adminClient: AdminClient) { }

  ngOnInit(): void {
    this.workloadId = this.route.snapshot.queryParams["workloadId"];
    if (!this.workloadId) {

      alert("Error: workloadId not specified");
      return;
    }

    this.adminClient.getFinishedWorkload(this.workloadId).subscribe(wl => {
      this.workload = wl;
      if (!wl.resultHdUrl) {
        this.adminClient.startHdGeneration(this.workloadId).subscribe(suc => this.startHdGenSuccess = true, err => {
          this.startHdGenSuccess = false;
          console.log(err);

        })
      }
    });


  }

}
