import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[noSpecialChars]'
})
export class NoSpecialCharsDirective {

  constructor() { }

  @HostListener('input', ['$event']) onKeyDown(e) {

    const pattern = /[^a-zA-Z0-9!`´ÄÖÜäö@üß"§$%&/()\[\]\\{}=?*+#'°^_#<>.:,;\s-]/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (pattern.test(event.target["value"])) {
      event.target["value"] = event.target["value"].replace(/[^a-zA-Z0-9!`´ÄÖÜäö@üß"§$%&/()\[\]\\{}=?*+#'°^_#<>.:,;\s-]/g, "");
      // invalid character, prevent input

    }
  }
}
