import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { Cookies } from 'src/app/legal/cookie.service';
import { Observable, Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class CustomizerControlsService {

  public onPageChanged: Observable<{ id: string, isBack: boolean }>;
  public _onPageChanged = new Subject<{ id: string, isBack: boolean }>();

  private state: CustomizerControlsStorage;
  public nextPages: any[] = [
    "favorite",
    // "intensity",
    "product",
  ]
  private curPage;
  public pastPages = [];

  public nextPage() {
    let last = this.nextPages.shift();
    this.pastPages.unshift(this.curPage);
    this.curPage = last;
    this._onPageChanged.next({ id: last, isBack: false });
  }


  public canGoBack() {
    return !isNullOrUndefined(this.pastPages[0])
  }

  public prevPage() {
    if (this.pastPages[0]) {
      let last = this.pastPages.shift();
      this.nextPages.unshift(this.curPage);
      this.curPage = last;
      this._onPageChanged.next({ id: last, isBack: true });
    }

  }

  public insertPages(pages: string[]) {
    this.nextPages.unshift(...pages);
  }



  public posterPages = [
    "format",
    "review",
  ]



  public get currentPage(): number {
    return this.state.currentPage;
  }
  public set currentPage(v: number) {
    this.state.currentPage = v;
    this.save();
  }

  private save() {
    this.storage.setItem(Cookies.customizer_state, JSON.stringify(this.state));
  }

  constructor(private storage: StorageService) {
    this.state = JSON.parse(storage.getItem(Cookies.customizer_state)) || new CustomizerControlsStorage();
    this.onPageChanged = this._onPageChanged.asObservable();
  }
}

class CustomizerControlsStorage {
  currentPage: number = 0;

}

export enum CustomizerControlsStates {
  Advice,
  Simple,
  Advanced,
}
