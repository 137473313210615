import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-landing-hero-section-static',
  templateUrl: './landing-hero-section-static.component.html',
  styleUrls: ['./landing-hero-section-static.component.scss']
})
export class LandingHeroSectionStaticComponent implements OnInit {

  @Input()
  src;

  @Input()
  shapeClass = "shape-primary";

  constructor() { }

  ngOnInit(): void {
  }

}
