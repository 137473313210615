import { Component, Input, Output, EventEmitter, Type, ViewChild } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Content } from '@angular/compiler/src/render3/r3_ast';

@Component({
  selector: 'app-modal-base',
  templateUrl: './modal-base.component.html',
  styleUrls: ['./modal-base.component.scss']
})
export class ModalBaseComponent {
  @Output() onModalClose = new EventEmitter<any>();
  @Input() title: string;
  @Input() subtitle: string;
  @Input() showCross: boolean = true;
  @Input() size: "lg" | "sm";
  @Input() closable: boolean = true;
  @Input() centeredVertically = true;

  @ViewChild("content") content;

  private modal: NgbModalRef;
  constructor(private modalService: NgbModal) { }

  public open() {
    console.log("modal open");

    if (this.content)
      this.modal = this.modalService.open(this.content, { size: this.size, centered: this.centeredVertically });
    this.modal.result.then((result) => {
      this.onClose(result);

    }, (reason) => {
      this.onClose(reason);
    });
  }

  private onClose(reason) {
    if (!this.closable) {
      console.log("reopen");

      this.open();
    }
    else {
      this.onModalClose.emit(reason);

    }
  }

  public close() {
    this.modal.close();
  }

}