import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Translateable } from 'src/app/globalization/translateable';
import { BaseFormGroupControlValueAccessor } from '../base-form-group-control-value-accessor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from '../base-control-value-accessor';

@Component({
  selector: 'app-api-datasource-radio-group',
  templateUrl: './api-datasource-radio-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ApiDatasourceRadioGroupComponent),
      multi: true
    }
  ],
  styleUrls: ['./api-datasource-radio-group.component.sass']
})
//List of radio buttons, with their (multilanguage) data loaded from the server
export class ApiDatasourceRadioGroupComponent extends BaseControlValueAccessor implements OnInit {
  @Input() observable: Observable<Translateable[]>;
  @Input() label: string;

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
