export function isNullOrWhitespace(input) {

    if (typeof input === 'undefined' || input == null) return true;

    if (typeof input === "string")
        return input.replace(/\s/g, '').length < 1;
    else
        return false;
}

export function isElementInViewport(el) {


    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

export function getRoundedDate(dateTime: any, hours = 0) {

    if (dateTime.year && dateTime.month && dateTime.day) {
        let originalDate = new Date(dateTime.year, dateTime.month - 1, dateTime.day);
        let offset = new Date(originalDate.getTime() + 43200000);
        let rounded = new Date();
        rounded.setUTCFullYear(offset.getFullYear(), offset.getMonth(), offset.getDate());
        rounded.setUTCHours(hours, 0, 0, 0);

        return rounded;
    }
    else if (typeof dateTime === "string") {
        let originalDate = new Date(dateTime as string);
        let offset = new Date(originalDate.getTime() + 43200000);
        let rounded = new Date();
        rounded.setUTCFullYear(offset.getFullYear(), offset.getMonth(), offset.getDate());
        rounded.setUTCHours(hours, 0, 0, 0);

        return rounded;
    }
    else {
        let offset = new Date(dateTime.getTime() + 43200000);
        let rounded = new Date();
        rounded.setUTCFullYear(offset.getFullYear(), offset.getMonth(), offset.getDate());
        rounded.setUTCHours(hours, 0, 0, 0);

        return rounded;
    }

}