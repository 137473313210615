import { Injectable } from '@angular/core';
import { RegistrationController } from './registration-controller';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../auth/authentication.service';
import { UserService } from '../auth/user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RegistrationControllerFactoryService {

  constructor(private formBuilder: FormBuilder, private authService: AuthenticationService, public userService: UserService, private router: Router) { }

  public getRegistrationController() {
    return new RegistrationController(this.formBuilder, this.authService, this.userService, this.router);
  }
}
