import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';

import { FileUploadModule } from 'ng2-file-upload';
import { PopupBaseModule } from '../popup-base/popup-base.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponentsModule } from '../simple-components/simple-components.module';
import { NouisliderModule } from 'ng2-nouislider';
import { HttpClientModule } from '@angular/common/http';
import { StyleSelectorComponent } from './style-selector/style-selector.component';
import { PageComponentsModule } from '../page-components/page-components.module';
import { S3UploaderComponent } from './s3-uploader/s3-uploader.component';



@NgModule({
  declarations: [
    ImageUploaderComponent,
    StyleSelectorComponent,
    S3UploaderComponent
  ],
  imports: [
    PopupBaseModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    FileUploadModule,
    RouterModule,
    NgbModule,
    SimpleComponentsModule,
    PageComponentsModule,
    ReactiveFormsModule,
    NouisliderModule,
  ],
  exports: [
    StyleSelectorComponent,
    ImageUploaderComponent,
    S3UploaderComponent,
  ]
})
export class FormElementsModule { }
