import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Cookies } from '../legal/cookie.service';
import { CustomizedWorkload } from '../customizer/customizer.service';

@Injectable({
  providedIn: 'root'
})
export class SiteStateStorageService {


  private _siteState: SiteState;
  public get siteState(): SiteState {
    if (this._siteState == null)
      this._siteState = JSON.parse(this.storage.getItem(Cookies.siteState));

    if (this._siteState == null)
      this.siteState = new SiteState()

    return this._siteState;
  }
  public set siteState(v: SiteState) {
    this._siteState = v;
    this.storage.setItem(Cookies.siteState, JSON.stringify(v));
  }

  public notifySiteStateUpdated() {
    this.siteState = this.siteState;
  }


  constructor(private storage: StorageService) { }


}

class SiteState {
  hasSeenConsentBar: boolean = false;
  workload: CustomizedWorkload = new CustomizedWorkload();
}
