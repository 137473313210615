import { Injectable } from '@angular/core';
import { Xliff2, Xliff } from '@angular/compiler';


declare const require;
//const content = require('!!raw-loader!./../messages.de.xlf');
@Injectable({
  providedIn: 'root'
})
export class I18nService {
  //  private readonly xliff: any = new Xliff().load(content, '');

  constructor() { }

  get(key: string): string {
    /*  if (!this.xliff.i18nNodesByMsgId[key]) {
       console.log("Missing translation for " + key);
       return null;
 
     }
 
     return this.xliff.i18nNodesByMsgId[key][0].value; */
    return "";
  }

}
