import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppRoutes } from '../Routes';
import { UserService } from './user.service';
import { UserRole } from './data/user-role.enum';

@Injectable({
  providedIn: 'root'
})
export class LoginRedirectService implements OnDestroy {

  private sub: Subscription;


  public afterLoginRedirectRoute = AppRoutes.home;
  constructor(private router: Router, private userService: UserService) {

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url != '/' + AppRoutes.login) {
          this.afterLoginRedirectRoute = AppRoutes.home;
        }
      }
    });



  }

  updateAfterLoginRoute() {

    this.afterLoginRedirectRoute = AppRoutes.home;

  }

  public goToLoginPage(afterLoginRedirectRoute: string) {
    this.afterLoginRedirectRoute = afterLoginRedirectRoute;
    this.router.navigateByUrl(AppRoutes.login);
  }

  ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
  }
}
