import { Component, OnInit, ViewChild } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { StorageService } from 'src/app/services/storage.service';
import { CookieConsentService, ConsentChangedEvent } from '../cookie-consent.service';
import { CookieEditorPopupComponent } from 'src/app/legal/cookie-editor-popup/cookie-editor-popup.component';
import { Cookies } from '../cookie.service';
import { SiteStateStorageService } from 'src/app/services/site-state-storage.service';

@Component({
  selector: 'app-consent-bar',
  templateUrl: './consent-bar.component.html',
  styleUrls: ['./consent-bar.component.scss']
})
export class ConsentBarComponent implements OnInit {

  /* @ViewChild(CookieEditorPopupComponent)
   popup: CookieEditorPopupComponent;*/

  public hasSeen: boolean;

  public accept() {
    this.hasSeen = true;
    this.cookieConsent.setConsent(new ConsentChangedEvent(null, true));
    this.siteState.siteState.hasSeenConsentBar = true;
    this.siteState.notifySiteStateUpdated();
  }

  openCookieDialog() {
    //  this.popup.open();

  }

  constructor(public responsive: ResponsiveService,
    private cookieConsent: CookieConsentService,
    private storageService: StorageService,
    private siteState: SiteStateStorageService) { }

  ngOnInit() {
    this.cookieConsent.onConsentChange.subscribe(x => this.hasSeen = true);


    this.hasSeen = this.siteState.siteState.hasSeenConsentBar;
  }

}
