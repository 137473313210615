import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user.service';
import { UserRole } from '../data/user-role.enum';
import { AppRoutes } from 'src/app/Routes';
import { LoginRedirectService } from '../login-redirect.service';

@Injectable({
  providedIn: 'root'
})
export class AdminOnlyGuardGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router, private loginService: LoginRedirectService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if (!this.userService.currentUser.isLoggedIn) {
      this.loginService.goToLoginPage(state.url);
    }
    else if (this.userService.currentUser.role != UserRole.Admin) {
      this.router.navigateByUrl(AppRoutes.errorLanding);
      return false;
    }
    else {
      return true;
    }
  }

}
