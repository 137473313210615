import { Injectable } from '@angular/core';
import { Cookie, CookieCategories, I18nItem } from './cookie';

@Injectable({
  providedIn: 'root'
})
export class CookieService {


  public getByCategory(cat: I18nItem): Cookie[] {
    return Object.keys(Cookies).map(key => Cookies[key]).filter(cookie => cookie.category == cat);
  }

  public getByKey(key: string): Cookie {
    let cookie;
    Object.keys(Cookies).forEach(x => {
      if (x === key)
        cookie = Cookies[key];
    });

    return cookie;
  }

  public getAll(): Cookie[] {
    return Object.keys(Cookies).map(x => Cookies[x]);
  }

  constructor() { }
}

export var Cookies = {
  current_user: new Cookie("current_user", CookieCategories.PREFERENCES, new I18nItem("currentUserCookieText", "currentUserCookieDesc")),
  consentCookies: new Cookie("consentCookies", CookieCategories.NECESSARY, new I18nItem("cookieConsentCookieTitle", "cookieConsentCookieDesc")),
  siteState: new Cookie("site_state", CookieCategories.PREFERENCES, new I18nItem("siteStateCookieTitle", "siteStateCookieDesc")),
  customizer_state: new Cookie("customizer_state", CookieCategories.PREFERENCES, new I18nItem("customizerStateCookieTitle", "customizerStateCookieDesc")),
}