import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthPagesRoutingModule } from './auth-pages-routing.module';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormComponentsModule } from '../form-components/form-components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginOptionsComponent } from './components/login-options/login-options.component';
import { EmailVerifyLandingPageComponent } from './components/email-verify-landing-page/email-verify-landing-page.component';
import { SimpleComponentsModule } from '../simple-components/simple-components.module';
import { VerifyPasswordComponent } from './components/verify-password/verify-password.component';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    LoginOptionsComponent,
    EmailVerifyLandingPageComponent,
    VerifyPasswordComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormComponentsModule,
    NgbModule,
    SimpleComponentsModule,
    CommonModule,
    AuthPagesRoutingModule
  ],
  exports: [
    LoginComponent,
    VerifyPasswordComponent
  ]
})
export class AuthPagesModule { }
