import { I18nService } from "../services/i18n.service";

export class Cookie {

    constructor(public key: string, public category: I18nItem, public text: I18nItem) {

    }
}

export class I18nItem {
    nameI18nId;
    descriptionI18nId;

    name;
    description;

    load(i18n: I18nService) {
        this.name = i18n.get(this.nameI18nId);
        this.description = i18n.get(this.descriptionI18nId);
    }

    constructor(nameI18nId, descriptionI18nId) {
        this.nameI18nId = nameI18nId;
        this.descriptionI18nId = descriptionI18nId;
    }
}

export function getCookieCategories(): I18nItem[] {
    return Object.keys(CookieCategories).map(key => CookieCategories[key]);
}

export class CookieCategories {
    public static NECESSARY = new I18nItem("cookieCatNecessaryName", "cookieCatNecessaryDesc");
    public static ANALYTICS = new I18nItem("cookieCatAnalyticalName", "cookieCatAnalyticalDesc");
    public static OTHER = new I18nItem("cookieCatOtherName", "cookieCatOtherDesc");
    public static PREFERENCES = new I18nItem("cookieCatPrefsName", "cookieCatPrefsDesc");
}