import { Component, OnInit, Input, forwardRef, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from '../base-control-value-accessor';
import { LocalizationService } from 'src/app/globalization/localization.service';

@Component({
  selector: 'app-text-form-field',
  templateUrl: './text-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextFormFieldComponent),
    }
  ],
  styleUrls: ['./text-form-field.component.scss']
})

export class TextFormFieldComponent extends BaseControlValueAccessor implements OnInit {

  //Optional - FormControl of this text field. 
  //If set automatically checks and displays error messages for some common errors like required
  @Input() control: AbstractControl = undefined;
  //Sets style for transparent backgrounds
  @Input() transparent: false;
  //Optional - only shows errors if this is set to true
  //Set this to true after the user tries to submit a form for the first time
  @Input() submitted: boolean;
  //Label for this text field
  @Input() formLabel: string;
  //Description label below the text field
  @Input() description: string;
  @Input() placeholder: string = "";
  @Input() alternativeStyle = false;
  //Id of the actual html inputs
  @Input() inputId;
  //Input type
  //Can be any type and input can have as well as
  //money: Shows currency character for locale
  //distance: Shows distance unit for locale
  //textarea
  @Input() type: 'money' | 'distance' | 'textarea' | 'unit' | string = "text";
  //Number of rows for text area
  @Input() rows: number = 5;
  @Input() disabled = false;
  //fontawesome icon class eg. 'fa-circle' to be displayed on the left side of a single line text field
  @Input() icon = null;
  @Input() labelPosition: 'top' | 'left' = 'top';
  //Displays info icon with tooltip
  @Input() info = null;
  @Input()
  characterLimit = null;

  //Shows a no branding warning when true and focused
  @Input() noBranding = false;

  @Output() blur: EventEmitter<any>;
  @Input() unitAsString: String;

  //The input elementref, only valid if type != textarea
  @ViewChild("textFormFieldInput")
  inputElement: ElementRef;

  charCount = 0;
  currency;
  appendText;
  actualType;

  public get value() {
    if (this.type == "number")
      return Number.parseFloat(this.val);
    return this.val;
  }
  public set value(val: any) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
    this.onValueUpdated(val);
  }

  public inputFocus = false;
  constructor(private ln: LocalizationService) {
    super();
    this.blur = new EventEmitter();
    this.currency = ln.currency;
  }

  ngOnInit() {
    if (this.description === undefined)
      this.description = "";

    if (this.formLabel === undefined)
      this.formLabel = "";

    if (this.type == "money") {
      this.appendText = this.ln.currency;
      this.actualType = "number";
    }
    else if (this.type == "distance") {
      this.appendText = this.ln.distanceUnit;
      this.actualType = "number";
    }
    else if (this.type == "unit") {
      this.appendText = this.unitAsString;
      this.actualType = "number";
    }
    else {
      this.actualType = this.type;
    }

    this.updateCharCount();
  }

  onValueUpdated(val) {
    this.updateCharCount();
  }

  onTextChange(val) {
    this.value = val;
    this.updateCharCount();

  }

  updateCharCount() {
    if (this.characterLimit && this.value) {
      this.charCount = this.value.split("").length;
    }
  }

  get isError() {
    return this.submitted && this.control && (this.control.invalid && (this.control.dirty || this.control.touched));
  }

}