import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-how-it-works-section',
  templateUrl: './landing-how-it-works-section.component.html',
  styleUrls: ['./landing-how-it-works-section.component.scss']
})
export class LandingHowItWorksSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  goToErstellen() {
    document.querySelector("#erstellen").scrollIntoView();
  }
}
