import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  windowWidth: number;
  sizes = new Map();

  constructor() {
    this.sizes.set('sm', 576);
    this.sizes.set('md', 768);
    this.sizes.set('lg', 992);
    this.sizes.set('xl', 1200);
  }

  isAtLeast(size: string): boolean {
    return this.windowWidth >= this.sizes.get(size);
  }

  isAtMax(size: string): boolean {
    return this.windowWidth <= this.sizes.get(size);
  }



  onResize() {
    this.windowWidth = window.innerWidth;
  }
}

