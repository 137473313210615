import { Component, OnInit, HostListener } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { animateValue } from 'src/app/util/animate';

@Component({
  selector: 'app-landing-hero-section',
  templateUrl: './landing-hero-section.component.html',
  styleUrls: ['./landing-hero-section.component.scss']
})
export class LandingHeroSectionComponent implements OnInit {

  revealProgress = 50;
  constructor(public responsive: ResponsiveService) { }
  animStart = 40;
  animEnd = 60;

  stopanim = false;

  @HostListener('window:touchstart', ['$event'])
  @HostListener('window:mousedown', ['$event'])
  mouseUp(event) {
    this.stopanim = true;

  }

  animate() {

    animateValue(this.animStart, this.animEnd, 1500).subscribe(newProgress => {
      if (!this.stopanim)
        this.revealProgress = newProgress;
    }, err => { },
      () => {
        this.animStart = this.animEnd + this.animStart;
        this.animEnd = this.animStart - this.animEnd;
        this.animStart = this.animStart - this.animEnd;
        setTimeout(() => {
          this.animate();
        }, this.animStart == 100 ? 3000 : 0);
      })
  }

  ngOnInit(): void {
    //this.animate();
  }
  goToErstellen() {
    document.querySelector("#erstellen").scrollIntoView();
  }
}
