
import { Component, OnInit, forwardRef, AfterViewInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TextFormFieldComponent } from '../text-form-field/text-form-field.component';
import { BaseControlValueAccessor } from '../base-control-value-accessor';
import { of, Observable } from 'rxjs';
import { ApiEndpoints } from 'src/app/api-interface/api-endpoints';
import { HttpClient } from '@angular/common/http';
import { map, debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { isNullOrWhitespace } from 'src/app/util/helpers';
import { ZipCode } from 'src/app/zip-code/zip-code';

@Component({
  selector: 'app-city-selector',
  templateUrl: './city-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CitySelectorComponent),
      multi: true
    }
  ],
  styleUrls: ['./city-selector.component.scss']
})
export class CitySelectorComponent extends BaseControlValueAccessor implements OnInit {

  public zipCode: ZipCode;

  @Output()
  change = new EventEmitter();

  @Input()
  control: FormControl;

  @Input()
  submitted: boolean;

  @Input()
  transparent = false;

  model: any;
  oldModel: any;
  searching = false;
  searchFailed = false;

  public get value() {


    return this.val;
  }
  public set value(val: any) {

    if (!this.zipCode || this.zipCode.plz !== val) {
      this.startSearch(val).subscribe(val => {
        if ((val as any).length) {
          this.model = val[0];
          this.zipCode = val[0];
          this.val = this.model.plz;
          this.onChange(this.val);
          this.onTouched();
          this.onValueUpdated(this.val);
        }
      });
    }
    else {
      this.onChange(val);
      this.onTouched();
      this.onValueUpdated(val);
    }

  }


  constructor(private http: HttpClient, private ep: ApiEndpoints) {
    super();
  }

  onselect(zipcode) {
    this.zipCode = zipcode;
    this.value = zipcode.plz;
    this.change.emit();
  }


  clear() {

    this.oldModel = this.model;
    this.model = { plz: "", cityName: "" };

  }

  blur() {

    if (this.oldModel && this.oldModel.plz != null && this.model.plz == "") {

      this.model = this.oldModel;
    }
    else if (typeof this.model != "object") {

      this.loadBestMatch(this.model);

    }

  }

  enterPress() {
    this.loadBestMatch(this.model);
  }

  loadBestMatch(loadBestMatchTo, i = 0) {
    //Load the best match
    this.startSearch(loadBestMatchTo).subscribe(val => {
      if ((val as any).length) {
        this.model = val[0];
        this.zipCode = val[0];
        this.onselect(val[0])
      }
      //Try using a similar zipcode if none was found
      else if (typeof this.model == "string") {
        if (i <= 6 && this.model.length >= 4) {
          i++;
          let shorterModel = this.model.substr(0, this.model.length - i);
          this.loadBestMatch(shorterModel, i);
        }

      }
    });
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? [] :
        this.startSearch(term as string).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );

  formatter = (x) => (!isNullOrWhitespace(x.plz) ? x.plz + " " : "") + x.cityName;


  ngOnInit(): void {

  }

  startSearch(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http
      .get(this.ep.getZipSearchUrl(term));
  }

}
