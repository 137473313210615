import { ControlValueAccessor, AbstractControl } from '@angular/forms';
import { Input } from '@angular/core';

//Base class for ControlValueAccessors. 
//Binds to the value variable
export class BaseControlValueAccessor implements ControlValueAccessor {
    public val: any;
    @Input() public control: AbstractControl;
    @Input() public submitted = true;

    public get value() {
        return this.val;
    }
    public set value(val: any) {
        this.val = val;
        this.onChange(val);
        this.onTouched();
        this.onValueUpdated(val);
    }

    protected onValueUpdated(val: any) { }

    public disabled = false;

    notifyChangedByUser() {
        this.onChange(this.value);
        this.onTouched();
    }

    onChange: any = () => { };
    onTouched: any = () => { };

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
