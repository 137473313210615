import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { AuthHttpClient } from '../auth/auth-http-client/auth-http-client';
import { UserRole } from '../auth/data/user-role.enum';
import { Observable } from 'rxjs';
import { Translateable } from './translateable';
import { shareReplay, single } from 'rxjs/operators';
import { ApiEndpoints } from '../api-interface/api-endpoints';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslateableService {

  public exampleCategory = { category: "Example" } as TranslateableCategory


  public paExtraOfferHeaderId = 109;
  public lightExtraOfferHeaderId = 110;

  constructor(private http: HttpClient, private endpoints: ApiEndpoints, @Inject(LOCALE_ID) private locale: string) { }

  private categoryCache = new Map();
  private singleChache = new Map();

  public getTranslateablesByIds(ids: number[]): Observable<Translateable[]> {
    return this.http.get<Translateable[]>(this.endpoints.getGetTranslablesByIdsUrl(ids)).pipe(shareReplay());
  }

  public getTranslateablesOfCategory(translateableCategory: TranslateableCategory, locale: string = null): Observable<Translateable[]> {

    if (!locale)
      locale = this.locale;

    if (!this.categoryCache.has(translateableCategory.category)) {
      let obs = this.http.get<Translateable[]>(this.endpoints.getGetTranslateablesByCategoryUrl(translateableCategory.category, locale)).pipe(shareReplay());
      this.categoryCache.set(translateableCategory.category, obs);
      obs.subscribe(val => val.forEach(trans => this.singleChache.set(trans.id, trans)))
      return obs;
    }
    else
      return this.categoryCache.get(translateableCategory.category);
  }

  public getTranslateablesOfCategoryByName(translateableCategoryName: string, locale: string = null): Observable<Translateable[]> {
    if (!locale)
      locale = this.locale;

    return this.getTranslateablesOfCategory({ category: translateableCategoryName } as TranslateableCategory, locale);

  }

  public getTranslateable(translateableId: number, locale: string = null): Observable<Translateable> {
    if (!locale)
      locale = this.locale;
    let obs = this.http.get<Translateable>(this.endpoints.getGetTranslateableUrl(translateableId, locale)).pipe(shareReplay());

    obs.subscribe(val => this.singleChache.set(val.id, val));
    return obs;
  }
}


export interface TranslateableCategory {
  category: string;
}