import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminOutletComponent } from './admin-outlet/admin-outlet.component';
import { AdminNavigationComponent } from './admin-navigation/admin-navigation.component';
import { StyleSettingsComponent } from './style-settings/style-settings.component';
import { StyleEditorItemComponent } from './style-editor-item/style-editor-item.component';
import { StyleEditorComponent } from './style-editor/style-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormComponentsModule } from '../form-components/form-components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { AdminResultPreviewComponent } from './admin-result-preview/admin-result-preview.component';
import { PageComponentsModule } from '../page-components/page-components.module';
import { CreateHdComponent } from './create-hd/create-hd.component';


@NgModule({
  declarations: [
    AdminOutletComponent
    , AdminNavigationComponent,
    StyleSettingsComponent,
    StyleEditorItemComponent
    , StyleEditorComponent, AdminResultPreviewComponent, CreateHdComponent],
  imports: [
    CommonModule,
    FormComponentsModule,
    FormsModule,
    PageComponentsModule,
    FormElementsModule,
    NgbModule,
    ReactiveFormsModule,
    AdminRoutingModule
  ]
})
export class AdminModule { }
