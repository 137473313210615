import { Component, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RequestClient, FinishedRequestDto, WorkloadDto, FinishedWorkloadDto, GdprClient } from 'src/app/api-interface/clients';
import { AppRoutes } from 'src/app/Routes';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { SiteStateStorageService } from 'src/app/services/site-state-storage.service';
import { CustomizedWorkload } from '../customizer.service';
import { UserService } from 'src/app/auth/user.service';

@Component({
  selector: 'app-customizer-page',
  templateUrl: './customizer-page.component.html',
  styleUrls: ['./customizer-page.component.scss']
})
export class CustomizerPageComponent implements OnInit {

  constructor(private siteStateServ: SiteStateStorageService,
    public responsive: ResponsiveService, private userService: UserService,
    private router: Router, private route: ActivatedRoute, private requestClient: RequestClient,
    private gdpr: GdprClient) { }



  images = [1, 2, 3, 4].map((n) => `../../../assets/img/theme/slider/${n}.jpg`);

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  requestId;
  showOriginal = false;
  opacity = 100;
  request: FinishedRequestDto;
  selectedWorkload: FinishedWorkloadDto;
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  selectWorkload(workload: FinishedWorkloadDto) {
    let state = this.siteStateServ.siteState;
    let customizedWorkload = state.workload ?? new CustomizedWorkload();
    customizedWorkload.workload = workload;
    this.selectedWorkload = customizedWorkload.workload;
    state.workload = customizedWorkload;
    this.siteStateServ.siteState = state;
    console.log(this.selectedWorkload);
  }

  updateOpacity() {
    let state = this.siteStateServ.siteState;
    state.workload.opacity = this.opacity;
    this.siteStateServ.siteState = state;
  }

  exportMyData() {
    this.gdpr.exportRequestData(this.requestId).subscribe(val => {
      alert("Wir haben deine Nutzerdaten an deine angegebene Email-Addresse gesendet");
    },
      err => {
        alert("Ein Fehler ist aufgetreten. Schick uns eine Email an info@artai.de um deine Nutzerdaten manuell zu exportieren.");
      });
  }

  deleteMyData() {
    if (confirm('Willst du wirklich deine Daten löschen? Du kannst dein Bild dann nicht mehr aufrufen, runterladen oder als Poster erhalten.')) {
      this.requestClient.deleteRequest(this.requestId).subscribe(val => {
        this.router.navigateByUrl(AppRoutes.home);
        alert("Deine daten wurden gelöscht");
      }, err => {
        console.log(err);

        alert("Ein Fehler ist aufgetreten. Für eine manuelle Löschung deiner Daten kontaktiere info@artai.de")
      });

    }
  }

  ngOnInit(): void {
    this.requestId = this.route.snapshot.queryParams["id"];
    if (!this.requestId)
      this.router.navigateByUrl(AppRoutes.errorLanding);

    //Load the request to customize
    this.requestClient.getFinishedRequest(this.requestId).subscribe(val => {
      this.request = val;
      console.log(val);

      this.selectWorkload(val.workloads[0])
    }, err => {
      this.router.navigateByUrl(AppRoutes.errorLanding);
    });
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
}
