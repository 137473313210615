import { Injectable } from '@angular/core';
import { Currencies } from './currencies';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  currency: string = Currencies.Euro;
  distanceUnit: string = "km";

  constructor() { }
}
