import { Component, OnInit } from '@angular/core';
import { CookieConsentService, CookieConsent } from '../cookie-consent.service';
import { I18nService } from 'src/app/services/i18n.service';

@Component({
  selector: 'app-cookies-table',
  templateUrl: './cookies-table.component.html',
  styleUrls: ['./cookies-table.component.scss']
})
export class CookiesTableComponent implements OnInit {

  consent: CookieConsent[];
  constructor(public cookieConsent: CookieConsentService, private i18n: I18nService) { }

  ngOnInit() {
    this.consent = this.cookieConsent.getCookieConsents();
    this.consent.forEach(x => {
      x.cookie.text.load(this.i18n);
      x.cookie.category.load(this.i18n);
    });

  }

}
