import { Component, OnInit, Input, Inject, LOCALE_ID, forwardRef, ChangeDetectorRef } from '@angular/core';
import { BaseControlValueAccessor } from '../base-control-value-accessor';
import { TranslateableService, TranslateableCategory } from 'src/app/globalization/translateable.service';
import { first } from 'rxjs/operators';
import { Translateable } from 'src/app/globalization/translateable';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-translateable-select',
  templateUrl: './translateable-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TranslateableSelectComponent),
    }
  ],
  styleUrls: ['./translateable-select.component.scss']
})
//A select/dropdown that loads its options from the server as a Translateable[]
export class TranslateableSelectComponent extends BaseControlValueAccessor implements OnInit {
  @Input()
  label: string;

  @Input()
  labelPosition: 'top' | 'left' = 'top';

  @Input()
  transparent: false;

  //Loads all translateable of this category
  @Input()
  translateableCategory: TranslateableCategory;

  //True wraps the selected value in an array
  @Input()
  valueAsArray = false;

  //Label for when nothings selected
  @Input()
  defaultItemLabel: string;

  //Translateable id of the selected default
  @Input()
  defaultSelectedId;

  @Input()
  showPlaceholder = false;

  @Input()
  placeholder: String;

  @Input()
  colored = false;

  @Input()
  isOrange = false;

  translateables: Translateable[];


  public get value() {
    return this.val;
  }

  public set value(val: any) {
    this.val = val;



    if (this.valueAsArray && val && !((val) instanceof Array))
      this.onChange([val]);
    else
      this.onChange(val);
    this.onTouched();

    setTimeout(() => {
      if (!this.val && this.defaultSelectedId) {
        this.value = this.defaultSelectedId;
        this.change.detectChanges();
      }
    }, 0);

  }

  onSelectionChange(val: any) {
    this.value = val;

  }

  constructor(private trans: TranslateableService, @Inject(LOCALE_ID) protected localeId: string, private change: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.trans.getTranslateablesOfCategory(this.translateableCategory, this.localeId).pipe(first()).subscribe(val => {
      this.translateables = val;

    });
  }

}
