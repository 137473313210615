
export const AppRoutes = {


    customizer: "customize",
    customizer2: "product",

    requestThankYou: "request-done-landing",

    user: {
        userData: "settings/user-data",
        requestPasswordChange: "request-password-change",
        changePassword: "change-password",
    },
    activateAccountLanding: "activate",
    errorLanding: "error",
    emailVerified: "emailVerified",
    unverifiedEmailError: "email-verify",
    login: "login",
    register: "register",
    home: "",
    newsletterUnsubscribed: "unsubscribed",
    impressum: "impressum",
    wiederruf: "wiederruf",
    privacyPolicy: "privacy-policy",
    agb: "agb",
    admin: {
        results: "admin/result",
        styles: "admin/styles",
        styleTweaker: "admin/style-tweaks",
    }


}
