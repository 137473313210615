import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from 'src/app/form-components/base-control-value-accessor';
import { StyleDto, StyleClient } from 'src/app/api-interface/clients';

@Component({
  selector: 'app-style-selector',
  templateUrl: './style-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => StyleSelectorComponent),
    }
  ],
  styleUrls: ['./style-selector.component.scss']
})
export class StyleSelectorComponent extends BaseControlValueAccessor implements OnInit {


  @Input()
  maxStyleCount = 99;

  styles: StyleDto[] = null;

  styleIndices = [];

  public get value(): StyleDto[] {
    return this.val;
  }
  public set value(val: StyleDto[]) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
    this.onValueUpdated(val);
  }


  updateSelectedStyle(i, style: StyleDto) {

    let transactionalValue = this.value || [];
    let styleIndexInArray = transactionalValue.findIndex(x => x.styleId == style.styleId);

    if (styleIndexInArray == -1) {
      if (transactionalValue.length < this.maxStyleCount) {
        transactionalValue.push(style);
        this.styleIndices[i] = transactionalValue.length;

      }
    }
    else {
      transactionalValue.splice(styleIndexInArray, 1);
    }


    this.value = transactionalValue;
    console.log(this.value);

  }

  hasStyle(style) {
    return this.value && this.value.some(x => x.styleId == style.styleId);
  }

  constructor(private styleServ: StyleClient) {
    super();
  }

  ngOnInit(): void {

    this.styleServ.getStyles().subscribe(styles => {
      this.styles = styles;
    });
  }

}
