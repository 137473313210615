import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateChars'
})
export class TruncateCharsPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    let limit = args[0] as number;
    let arr = value.split("");


    if (arr.length >= limit) {
      arr.splice(limit);
      value = "";
      arr.forEach(x => {
        value += x;
      })
      value += "\u2026";
    }
    return value;
  }

}
