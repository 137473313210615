import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-truncated-text',
  templateUrl: './truncated-text.component.html',
  styleUrls: ['./truncated-text.component.scss']
})
export class TruncatedTextComponent implements OnInit {

  @Input()
  limitLines;
  @Input()
  limitChars;
  @Input()
  whiteText = false;

  @Input()
  overflow = false;

  expandedCount = Number.MAX_SAFE_INTEGER;

  @Input()
  text = "";

  @Input()
  arr = [];

  @Input()
  dataType: string = 'text';

  truncated = true;
  currentTruncation;

  toggleTruncation() {
    if (this.limitLines) {
      //Limit length by line count
      if (this.currentTruncation == this.limitLines) {
        this.currentTruncation = this.expandedCount;
        this.truncated = false;
      }
      else if (this.currentTruncation == this.expandedCount) {
        this.currentTruncation = this.limitLines;
        this.truncated = true;
      }
      else {
        this.currentTruncation = this.expandedCount;
        this.truncated = true;
      }
    }
    else if (this.limitChars) {
      //Limit length by char count
      if (this.currentTruncation == this.limitChars) {
        this.currentTruncation = this.expandedCount;
        this.truncated = false;
      }
      else if (this.currentTruncation == this.expandedCount) {
        this.currentTruncation = this.limitChars;
        this.truncated = true;
      }
      else {
        this.currentTruncation = this.expandedCount;
        this.truncated = true;
      }
    }
    else {
      console.error("Truncated text component: Please provide either limitLines or limitChars");
    }

  }

  constructor() { }

  ngOnInit() {
    this.currentTruncation = this.limitLines || this.limitChars;
  }

}
