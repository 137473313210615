import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../auth/user.service';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    subs = new Map;

    constructor(private userService: UserService, private authService: AuthenticationService) {

    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {


        if (this.userService.currentUser.isLoggedIn && request.url.indexOf(environment.apiUrl) != -1) {
            request = this.attachAuthHeader(request);
        }

        return next.handle(request);
    }

    private attachAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
        let headers = request.headers.append("Authorization", "Bearer " + this.userService.currentUser.loginToken.jwtToken);
        return request.clone({ headers });
    }
}
