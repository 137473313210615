import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { TextFormFieldComponent } from '../text-form-field/text-form-field.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizationService } from 'src/app/globalization/localization.service';

@Component({
  selector: 'app-toggled-text-form-field',
  templateUrl: './toggled-text-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ToggledTextFormFieldComponent),
    }
  ],
  styleUrls: ['./toggled-text-form-field.component.scss']
})
//A checkbox, that shows a text field when checked / unchecked
export class ToggledTextFormFieldComponent extends TextFormFieldComponent {
  //The value this has component has when the textbox is hidden
  @Input() valueWhenHidden: string;
  //label for the checkbox
  @Input() toggleLabel: string;
  @Input() checked: boolean = true;
  @Input() hideWhenChecked = true;

  private hiddenValue: any;

  get value() {
    return this.val;
  }
  set value(val: any) {
    this.val = val;
    this.onChange(val);
    this.onTouched();

    if (val != -1) {
      this.checked = !this.hideWhenChecked;
    }
    else {
      this.checked = this.hideWhenChecked;
    }
  }

  constructor(ln: LocalizationService) {
    super(ln);
  }

  onToggle() {
    let nowHidden = !((!this.hideWhenChecked && this.checked) || (this.hideWhenChecked && !this.checked));

    if (nowHidden) {
      this.hiddenValue = this.value;
      this.value = this.valueWhenHidden;
    }
    else {
      this.value = this.hiddenValue;
    }

  }

}
