import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Guid } from 'src/app/util/guid';
import { S3Client } from 'src/app/api-interface/clients';
import { getFileExtension, getContentType } from 'src/app/util/image-helpers';
import { observable, Observable } from 'rxjs';

@Component({
  selector: 'app-s3-uploader',
  templateUrl: './s3-uploader.component.html',
  styleUrls: ['./s3-uploader.component.scss']
})
export class S3UploaderComponent implements OnInit {
  minWidth = 2000;
  minHeight = 1000;

  file: File;

  @Input()
  fileKey: string;

  CONTENT = "content";
  STYLE = "style"
  @Input()
  type: "content" | "style" = "content";

  @Input()
  preview;
  @Output()
  previewChange = new EventEmitter();

  //Emits on successful upload
  @Output()
  onUploaded = new EventEmitter();

  error = false;
  showErrorButton = false;
  invalidMimeType = false;
  fileTooSmall = false;
  errorCode = null;
  localError = false;

  loading = false;
  success = false;
  showSuccessMessage = false;


  @Input()
  allowedMimeTypes = [
    "image/jpeg",
    "image/png",
    "image/tiff",
  ];



  constructor(private s3: S3Client) {
    this.fileKey = Guid.newGuid();
  }

  onFileChanged(event) {
    this.invalidMimeType = false;
    this.fileTooSmall = false;
    this.preview = null;
    this.previewChange.emit(this.preview)
    this.error = false;
    this.showErrorButton = false;

    this.file = event.target.files[0];
    setTimeout(() => {
      this.showErrorButton = false;

    }, 3000);

    if (!this.validateFile(event)) {
      return;
    }




    this.loading = true;

    let obs: Observable<any> = null;
    if (this.type == this.CONTENT)
      obs = this.s3.uploadContentImage({ data: this.file, fileName: this.fileKey }, this.fileKey);
    else if (this.type == this.STYLE)
      obs = this.s3.uploadStyleImage({ data: this.file, fileName: this.fileKey }, this.fileKey);

    obs.subscribe(val => {
      this.onUploaded.emit();
      this.loading = false;
      this.success = true;
      this.errorCode = null;
      this.localError = false;
      this.showSuccessMessage = true;
      setTimeout(() => {
        this.showSuccessMessage = false;
      }, 3000);
    },
      err => {
        this.loading = false;
        this.error = true;
        this.showErrorButton = true;
        this.errorCode = true;
        this.success = false;
        this.showSuccessMessage = false;

        if (err.error instanceof ErrorEvent) {
          this.localError = true;
          this.errorCode = null;
        } else {
          this.errorCode = err.status;
          this.localError = false;
        }
        setTimeout(() => {
          this.showErrorButton = false;
        }, 3000);
      });

  }



  validateFile(event) {
    if (event.target.files.length < 1)
      return false;

    const contentType = getContentType(getFileExtension(this.file));

    if (!this.allowedMimeTypes.includes(contentType)) {
      this.invalidMimeType = true;
      this.error = true;
      this.showErrorButton = true;

      return false;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(this.file);

    let reader = new FileReader();

    reader.readAsDataURL(this.file);
    reader.onload = () => {

      const width = img.naturalWidth;
      const height = img.naturalHeight;

      window.URL.revokeObjectURL(img.src);

      if (width < this.minWidth && height < this.minHeight) {
        this.error = true;
        this.fileTooSmall = true;
      }
      this.preview = reader.result;
      this.previewChange.emit(this.preview)

    };
    return true;
  }

  ngOnInit(): void {
  }

}

export enum Buckets {
  RESULTS = "artai-finished-style-transfers",
  CONTENT = "artai-style-transfer-queue",
  STYLES = "artai-styles"
}
