import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { AppRoutes } from '../Routes';
import { EmailVerifyLandingPageComponent } from './components/email-verify-landing-page/email-verify-landing-page.component';

const routes: Routes = [
  { path: AppRoutes.login, component: LoginComponent },
  // { path: AppRoutes.register, component: RegisterComponent },
  // { path: AppRoutes.activateAccountLanding, component: EmailVerifyLandingPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthPagesRoutingModule { }
