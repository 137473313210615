import { Component, OnInit, Input } from '@angular/core';
import { CustomizerControlsService } from '../customizer-controls.service';

@Component({
  selector: 'app-customizer-controls-page',
  templateUrl: './customizer-controls-page.component.html',
  styleUrls: ['./customizer-controls-page.component.scss']
})
export class CustomizerControlsPageComponent implements OnInit {

  @Input()
  pageId: string;

  @Input()
  title: string;

  @Input()
  skipable = true;

  @Input()
  showControls = true;

  constructor(public controlsServ: CustomizerControlsService) { }

  ngOnInit() {

  }

}
