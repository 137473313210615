import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBaseComponent } from './modal-base/modal-base.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ModalBaseComponent],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports: [
    ModalBaseComponent
  ]
})
export class PopupBaseModule { }
