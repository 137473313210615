import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-zoomable-image-card',
  templateUrl: './zoomable-image-card.component.html',
  styleUrls: ['./zoomable-image-card.component.scss']
})
export class ZoomableImageCardComponent implements OnInit {

  @Input()
  src;

  @Input()
  zoomSrc;

  @Input()
  isSquare = true;

  @Input()
  dropshadow = true;

  usedSrc;

  isZoomed = false;

  constructor() { }
  toggle() {
    this.isZoomed = !this.isZoomed;
    if (this.isZoomed)
      this.usedSrc = this.zoomSrc;
    else
      this.usedSrc = this.src;
  }
  ngOnInit(): void {
    this.usedSrc = this.src;
  }

}
