import { Component, OnInit, forwardRef, OnDestroy, Input, LOCALE_ID, Inject, Output, EventEmitter, OnChanges } from '@angular/core';
import { BaseFormGroupControlValueAccessor } from 'src/app/form-components/base-form-group-control-value-accessor';
import { NG_VALUE_ACCESSOR, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { Translateable } from 'src/app/globalization/translateable';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { BaseControlValueAccessor } from '../base-control-value-accessor';

@Component({
  selector: 'app-api-datasource-checkbox-list',
  templateUrl: './api-datasource-checkbox-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ApiDatasourceCheckboxListComponent),
      multi: true
    }
  ],
  styleUrls: ['./api-datasource-checkbox-list.component.scss']
})
//List of checkboxes, that have their (multilanguage) data loaded from the server
export class ApiDatasourceCheckboxListComponent extends BaseControlValueAccessor implements OnDestroy, OnInit {

  @Input()
  control;
  @Input() title: string;
  //The observable, which emits the options for the Checkboxes
  @Input("observable") translated$: Observable<Translateable[]>;
  //Alternative to observable; api endpoint from which to load the options (also as Translateable[])
  @Input() endpoint: string;
  //Styling for use on transparent bg
  @Input() transparent: false;
  //add subtext
  @Input() explanation: string;
  //Fires when the options are loaded from the server / observable
  @Output() onLoad = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();
  @Input() smallLabel: boolean;
  @Input() marginLeft: boolean = false;
  //Max number of (responisve) columns to split the values into 
  @Input() cols = 1;
  //Cuts of the checkboxes with a "show more/less" link after this amount of checkboxes
  @Input() collapseLimit;
  @Input() idOnly = false;
  collapsed = false;

  private optionsLoaded = false;
  //If a value is set before options are loaded, it is cached and gets applied when options are loaded from server
  private valueCache;

  colArray;

  sub: Subscription;
  translateables = new Map();

  //Contains the state of the checkbox eg. {monday: true, tuesday:false}
  state = {};

  //Defines getters and setters on state so changes can be broadcast
  observedState = {};

  // The returned value / the value you set to this is an array of the ids of all checked checkboxes. If you want this state: {monday: true, tuesday:false} you supply this [monday]
  get value() {
    let x = [];

    for (var property in this.state) {
      if (this.state.hasOwnProperty(property)) {
        if (this.state[property] == true && this.translateables.get(property))
          if (!this.idOnly)
            x.push(this.translateables.get(property));
          else
            x.push(this.translateables.get(property).id);
      }
    }

    return x;
  }
  set value(val: any) {

    if (this.optionsLoaded) {
      for (var key in this.state) {
        this.state[key] = false;
      }

      if (val) {
        for (let obj of val) {
          if (obj && obj.id)
            this.state[obj.id] = true;
          else
            this.state[obj] = true;

        }
      }

    }
    else
      this.valueCache = val;

    this.onChange(this.value);
    this.onTouched();
  }

  constructor(private builder: FormBuilder, private http: HttpClient, @Inject(LOCALE_ID) private locale: string) {
    super();
  }

  //Set up getters and setter upon the state so we can call onCHange when a checkbox is checked or unchecked
  setupObservedStateProperties() {
    for (var property in this.state) {
      if (this.state.hasOwnProperty(property)) {
        let prop = property;
        Object.defineProperty(this.observedState, prop, {
          get: () => { return this.state[prop]; },
          set: (value: any) => {

            if (this.state[prop] !== value) {
              this.state[prop] = value;
              this.onChange(this.value);
              this.onTouched();
            }
          },
        });
      }
    }
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  ngOnInit(): void {
    this.colArray = new Array(this.cols);

    if (!this.translated$ && this.endpoint) {
      this.translated$ = this.http.get<Translateable[]>(this.endpoint).pipe(shareReplay());
    }

    this.sub = this.translated$.subscribe(
      arr => {
        this.optionsLoaded = true;
        for (let index = 0; index < arr.length; index++) {
          const val = arr[index];
          this.translateables.set(val.id + "", val);
          this.state[val.id] = false;
        }

        if (this.collapseLimit && arr.length > this.collapseLimit) {
          this.collapsed = true;
        }

        this.onLoad.emit();
        this.setupObservedStateProperties();
        if (this.valueCache) {
          this.value = this.valueCache;

        }
      }
    );
  }



  static requiredValidator(control: AbstractControl): { [key: string]: any | null } {
    return control.value.length > 0 ? null : { 'required': true };
  }

  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
  }

}

