import { Observable, Subject } from "rxjs";

export function animateValue(start, end, duration): Observable<number> {
    const subject = new Subject<number>();
    setTimeout(() => {
        // assumes integer values for start and end

        var range = end - start;
        // no timer shorter than 50ms (not really visible any way)
        var minTimer = 25;
        // calc step time to show all interediate values
        var stepTime = Math.abs(Math.floor(duration / range));

        // never go below minTimer
        stepTime = Math.max(stepTime, minTimer);

        // get current time and calculate desired end time
        var startTime = new Date().getTime();
        var endTime = startTime + duration;
        var timer;

        function run() {
            var now = new Date().getTime();
            var remaining = Math.max((endTime - now) / duration, 0);
            //Current value from -0.5 to +0.5
            var value = Math.round(end - (remaining * range));
            var progress = 1 - remaining;
            var valueClampedScaled = (progress - 0.5) * 20;
            var smoothedClamped = 1 / (1 + Math.pow(2, -valueClampedScaled));

            subject.next(start + range * smoothedClamped);
            if (value == end) {
                subject.complete();
                clearInterval(timer);
            }
        }

        timer = setInterval(run, stepTime);
        run();

    }, 0);

    return subject.asObservable();
}