import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[numbersOnly]'
})
export class NumberOnlyDirective {

  @Input()
  numbersOnlyEnabled = true;

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    if (this.numbersOnlyEnabled) {
      const initalValue = this._el.nativeElement.value;

      let errorDiv = this._el.nativeElement.parentElement.parentElement.querySelector('#dotError');


      this._el.nativeElement.value = initalValue.replace(/[^0-9]/g, '');
      if (initalValue !== this._el.nativeElement.value) {
        if (errorDiv) {
          errorDiv.remove();
        }
        let inputContainer = this._el.nativeElement.parentElement.parentElement;
        inputContainer.insertAdjacentHTML('beforeend', '<p class="small" id="dotError" style="color: red;">We don\'t allow any special characters, please re-check the value.</p>')
        event.stopPropagation();
      }
    }

  }
}