import { BrowserModule } from '@angular/platform-browser';
import { NgModule, InjectionToken } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { SignupComponent } from './theme-pages/signup/signup.component';
import { LandingComponent } from './theme-pages/landing/landing.component';
import { ProfileComponent } from './theme-pages/profile/profile.component';
import { HomeComponent } from './theme-pages/home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './theme-pages/home/home.module';
import { LoginComponent } from './theme-pages/login/login.component';
import { LandingModule } from './landing/landing.module';
import { FileUploadModule } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { PagesModule } from './pages/pages.module';
import { CustomizerModule } from './customizer/customizer.module';
import { AuthPagesModule } from './auth-pages/auth-pages.module';
import { SimpleComponentsModule } from './simple-components/simple-components.module';
import { AdminModule } from 'src/app/admin/admin.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth-interceptor';
import { API_BASE_URL } from './api-interface/clients';
import { PrivacyPolicyComponent } from './legal/pages/privacy-policy/privacy-policy.component';
import { ImpressumComponent } from './legal/pages/impressum/impressum.component';
import { LegalModule } from './legal/legal.module';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { LandingAnimalsModule } from './landing-animals/landing-animals.module';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,

  ],
  imports: [
    BrowserModule,
    NgbModule,
    AuthPagesModule,
    LandingAnimalsModule,
    FileUploadModule,
    LandingModule,
    SimpleComponentsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    FormsModule,
    PagesModule,
    CustomizerModule,
    RouterModule,
    AdminModule,
    LegalModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: API_BASE_URL, useValue: environment.apiUrl },
    { provide: Window, useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
