import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { UserInfo } from '../data/user-info';
import { Cookies } from 'src/app/legal/cookie.service';


@Injectable({
  providedIn: 'root'
})
export class UserRepositoryService {

  constructor(private storage: StorageService) { }

  key = "current_user";

  getCurrentUser(): UserInfo {
    try {
      return JSON.parse(this.storage.getItem(Cookies.current_user));

    }
    catch{
      try {
        return JSON.parse(sessionStorage.getItem(Cookies.current_user.key));
      } catch (error) {
        return null;
      }
    }
  }

  setCurrentUser(user: UserInfo, useSessionStorage: boolean) {
    if (!useSessionStorage)
      this.storage.setItem(Cookies.current_user, JSON.stringify(user));
    else
      sessionStorage.setItem(Cookies.current_user.key, JSON.stringify(user));

  }

  deleteCurrentUser() {
    this.storage.deleteItem(Cookies.current_user);
    sessionStorage.removeItem(Cookies.current_user.key);
  }
}
