import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Translateable } from 'src/app/globalization/translateable';
import { BaseFormGroupControlValueAccessor } from '../base-form-group-control-value-accessor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from '../base-control-value-accessor';

@Component({
  selector: 'app-api-datasource-checkbox-group',
  templateUrl: './api-datasource-checkbox-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ApiDatasourceCheckboxGroupComponent),
      multi: true
    }
  ],
  styleUrls: ['./api-datasource-checkbox-group.component.scss']
})
//Group of buttons that act as checkboxes, with their (multilanguage) data loaded from the server
export class ApiDatasourceCheckboxGroupComponent extends BaseControlValueAccessor implements OnInit {
  //The observable, which emits the options for the Checkboxes
  @Input() observable: Observable<Translateable[]>;
  @Input() label: string;
  constructor() {
    super();
  }

  public get value(): number[] {
    let arr = [];
    Object.keys(this.val).forEach(key => {
      if (this.val[key])
        arr.push(parseInt(key));
    })

    return arr;
  }
  public set value(arr: number[]) {
    this.val = {};
    if (arr) {
      arr.forEach(x => {
        this.val[x] = true;
      })
    }
    this.onChange(arr);
    this.onTouched();
  }




  ngOnInit() {

  }

}
