import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateLines'
})
export class TruncateLinesPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    let limit = args[0] as number;
    let arr = value.split("\n");


    if (arr.length >= limit) {
      arr.splice(limit);
      value = "";
      arr.forEach(x => {
        value += x + "\n";
      })
    }
    return value;
  }

}
