import { Component, OnInit, Input, forwardRef, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from '../base-control-value-accessor';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, first } from 'rxjs/operators';
import { TranslateableCategory, TranslateableService } from 'src/app/globalization/translateable.service';
import { Translateable } from 'src/app/globalization/translateable';

@Component({
  selector: 'app-typeahead-text-form-field',
  templateUrl: './typeahead-text-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TypeaheadTextFormFieldComponent),
    }
  ],
  styleUrls: ['./typeahead-text-form-field.component.scss']
})
export class TypeaheadTextFormFieldComponent extends BaseControlValueAccessor implements OnInit, OnDestroy {


  @Input()
  label: string;

  //From which category to pull the options
  @Input()
  translateableCategory: TranslateableCategory;

  private options: Translateable[]
  public optionsLoaded = false;
  private onOptionsLoaded$ = new Subject();

  @Input()
  description: string;

  @Input()
  displayErrors: boolean;

  //Sets an error if the text in the textbox is not featured in the options array
  @Input()
  requireOption = true;

  //Control for setting & displaying errors
  @Input()
  control: FormControl;

  private subs: Subscription[] = [];

  public get value(): number {
    return this.val;
  }
  public set value(val: number) {
    this.val = val;

    //Find the option with the set id and set its text to the textbox
    if (this.optionsLoaded)
      this.stringVal = this.getTextFromIdFromOptions(this.val);
    else
      this.subs.push(this.onOptionsLoaded$.subscribe(x => this.stringVal = this.getTextFromIdFromOptions(this.val)));

    this.onChange(val);
    this.onTouched();
  }

  private getTextFromIdFromOptions(id: number) {
    let result;
    this.options.forEach(x => {
      if (x.id == id)
        result = x.text;
    });
    return result;
  }

  public stringVal;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(50),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.options.filter(v => v.text.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10).map(x => x.text))
    )

  constructor(private trans: TranslateableService, @Inject(LOCALE_ID) private locale: string) {
    super();
  }

  ngOnInit() {
    this.trans.getTranslateablesOfCategory(this.translateableCategory, this.locale).pipe(first()).subscribe(val => {
      this.options = val;
      this.optionsLoaded = true;
      this.onOptionsLoaded$.next();
    });
  }

  blur() {
    if (this.requireOption) {
      let result: Translateable;
      if (this.options && this.stringVal)
        result = this.options.find(o => o.text.toLowerCase() == this.stringVal.toLowerCase());

      if (result) {
        this.value = result.id;
        this.stringVal = result.text;
      }
      else if (this.control) {
        this.control.setErrors({ notAnOption: true });
      }

    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => {
      if (x && !x.closed)
        x.unsubscribe();
    })
  }

}
