import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FinishedRequestDto, FinishedWorkloadDto } from 'src/app/api-interface/clients';
import { ProductVariation, posterVariations, getPosterMinPrice, hdDownload, sdDownloadResolution, sdDownload, poster, canvasVariations, canvas } from '../poster.service';

@Component({
  selector: 'app-customizer-controls-scroll',
  templateUrl: './customizer-controls-scroll.component.html',
  styleUrls: ['./customizer-controls-scroll.component.scss']
})
export class CustomizerControlsScrollComponent implements OnInit {
  @Input()
  request: FinishedRequestDto;
  @Input()
  selectedWorkload: FinishedWorkloadDto;
  @Output()
  selectedWorkloadChange = new EventEmitter();

  visible = [];

  //Product types
  selectedProduct: ProductVariation = { type: null }
  productVariations = posterVariations;
  minPosterPrice = getPosterMinPrice();
  hdDownload = hdDownload;
  sdDownloadResolution = sdDownloadResolution;
  canvasVariations = canvasVariations;
  constructor() { }

  ngOnInit(): void {
  }

  selectWorkload(wl) {
    this.selectedWorkload = wl;
    this.selectedWorkloadChange.emit(wl);
  }

  show(id) {
    this.visible.push(id);
  }

  hide(id) {
    this.visible = this.visible.filter(x => x != id);
  }


  useDownload() {
    this.selectedProduct = sdDownload;
  }

  useHDDownload() {
    this.selectedProduct = hdDownload;

  }
  usePoster() {
    this.selectedProduct = poster;
  }
  useCanvas() {
    this.selectedProduct = canvas;
  }
  useFormat(product) {
    this.selectedProduct = product;
  }
}
