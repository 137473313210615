import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss']
})
export class ImageCardComponent implements OnInit {

  @Input()
  src;

  @Input()
  isSquare = true;

  @Input()
  dropshadow = true;



  constructor() { }

  ngOnInit(): void {
  }
}
